import React from 'react';
import { BiNotepad } from 'react-icons/bi';
import { FiUsers } from 'react-icons/fi';
import { ImUsers } from 'react-icons/im';
import { TbPremiumRights } from 'react-icons/tb';
import { Outlet, useParams } from 'react-router-dom';

import GenericDashboardLayout from '@/layouts/GenericDashboardLayout';
import PageTitle from '@/shared/components/Typography/PageTitle';

import SettingsNav from './components/SettingsNav';

const navItems = [
  {
    label: 'General',
    icon: <FiUsers className="mr-1" />,
    path: 'general-profile'
  },

  {
    label: 'Billing',
    icon: <BiNotepad className="mr-1" />,
    path: 'billing'
  },
  {
    label: 'Plans',
    icon: <TbPremiumRights className="mr-1" />,
    path: 'plans'
  },
  {
    label: 'Users',
    icon: <ImUsers className="mr-1" />,
    path: 'users'
  }
];

export default function Settings() {
  const { organization_id } = useParams();

  return (
    <GenericDashboardLayout>
      <div className="grid grid-cols-8 grid-rows-12 w-full h-full gap-1">
        <div className="row-span-1 col-span-8 items-center flex">
          <SettingsNav navItems={navItems} />
        </div>
        <div className="row-span-1 col-start-2 col-end-8 col-span-8">
          <PageTitle title="Settings" prevRedirectionLink={`/${organization_id}`} />
        </div>
        <div className="row-span-14 row-start-3 col-start-2 col-span-6 overflow-auto">
          <Outlet />
        </div>
      </div>
    </GenericDashboardLayout>
  );
}
