import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FiCheck } from 'react-icons/fi';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem
} from '@/components/ui/select';
import { Spinner } from '@/components/ui/spinner';
import { useToast } from '@/components/ui/use-toast';

import { useCreateClusterUser } from '../api/ClusterUsersApi';
export type ClusterUserFormData = {
  username: string;
  host_ip: string[];
  password_type: string;
  password: string;
  default_roles: string[];
};

const formSchema = z.object({
  username: z.string().min(3, {
    message: 'Workspace name must be at lest 3 characters.'
  }),
  password_type: z
    .string()
    .min(4, {
      message: 'Description must be at lest 4 characters.'
    })
    .min(1),
  password: z
    .string({
      required_error: 'Please select cloud.'
    })
    .min(1),
  host_ip: z
    .string({
      required_error: 'Please select region.'
    })
    .min(1),
  default_roles: z
    .string({
      required_error: 'Please select region.'
    })
    .min(1)
});

const password_types = [
  { name: 'plaintext_password', value: 'plaintext_password' },
  { name: 'sha256_password', value: 'sha256_password' },
  { name: 'sha256_hash', value: 'sha256_hash' },
  { name: 'double_sha1_password', value: 'double_sha1_password' },
  { name: 'double_sha1_hash', value: 'double_sha1_hash' }
];

type CreateClusterUserFormProps = {
  roles: any;
};

export default function CreateClusterUserForm({ roles }: CreateClusterUserFormProps) {
  const { organization_id, workspace_id, cluster_id } = useParams();
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const createClusterUserMutation = useCreateClusterUser({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!,
    config: {
      onSuccess: () => {
        queryClient.invalidateQueries('cluster-users');
        toast({
          title: 'Cluster user added.'
        });
      },
      onError: () => {
        queryClient.invalidateQueries('cluster-users');
        toast({
          title: 'Unable to add cluster user.'
        });
      }
    }
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
      password_type: '',
      password: '',
      host_ip: '',
      default_roles: ''
    }
  });
  function onSubmit(values: z.infer<typeof formSchema>) {
    const data: ClusterUserFormData = {
      username: values.username,
      password_type: values.password_type,
      password: values.password,
      host_ip: values.host_ip.length === 0 ? [] : values.host_ip.split(','),
      default_roles: [values.default_roles]
    };
    createClusterUserMutation.mutate(data);
  }

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="username" {...field} />
              </FormControl>
              <FormDescription>Name for cluster user</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password_type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password type</FormLabel>
              <Select onValueChange={field.onChange}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select password type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {password_types.map((r: any) => (
                    <SelectItem value={r.value} key={r.name}>
                      {r.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormDescription>Select password type</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input type="password" placeholder="******" {...field} />
              </FormControl>
              <FormDescription>Enter user password</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="host_ip"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Host IP</FormLabel>
              <FormControl>
                <Input placeholder="127.0.0.1, ::/0" {...field} />
              </FormControl>
              <FormDescription>Enter Host IPs</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="default_roles"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Default Roles</FormLabel>
              <Select onValueChange={field.onChange}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a default roles" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {roles.map((r: any) => (
                    <SelectItem value={r.name} key={r.name}>
                      {r.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormDescription>Select default role for the user</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-end w-full gap-2">
          {createClusterUserMutation.isLoading && (
            <Button className="w-36" disabled>
              <Spinner variant={'button'} />
            </Button>
          )}
          {createClusterUserMutation.isSuccess && (
            <Button className="pointer-events-none w-36">
              <FiCheck />
            </Button>
          )}
          {createClusterUserMutation.isError && (
            <Button variant={'destructive'} disabled className="w-36">
              x
            </Button>
          )}
          {createClusterUserMutation.isIdle && <Button type="submit">Create Cluster User</Button>}
        </div>
      </form>
    </Form>
  );
}
