/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { RiFileCopyLine } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';

import { IQueryEditorStore, useStore as useQueryStore } from '@/shared/store/query-editor';
import { checkIfClipboardAPI, copyToClipboard } from '@/shared/utils/clipboard';

import { ICluster } from '../types';

import ClusterStatus from './ClusterStatus';
import DeleteCluster from './DeleteCluster';

type Props = {
  cluster: ICluster;
};

export default function ClusterBar({ cluster }: Props) {
  const { name, status, endpoint, region } = cluster;
  const { workspace_id, organization_id } = useParams();
  const canCopyToClipboard = checkIfClipboardAPI();
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const [, setQueryEditorStore] = useQueryStore<IQueryEditorStore>();

  useEffect(() => {
    if (copiedToClipboard) {
      // setTimeout(() => {
      //   setCopiedToClipboard(false);
      // }, 3000);
    }
  }, [copiedToClipboard]);

  async function handleCopyToClipboard() {
    await copyToClipboard(endpoint);
    setCopiedToClipboard(true);
  }

  function handleExplore() {
    setQueryEditorStore({
      query: '',
      explain: false,
      result: null
    });
  }

  const hostname = endpoint ?? 'NA';

  return (
    <div className="bg-card shadow-sm rounded-md w-full h-14">
      <div className="grid w-full grid-cols-8 h-full px-4 py-2 items-center">
        <h2 className="text-base-900 text-base col-span-2 items-center">{name}</h2>
        <div className="col-span-3 flex items-center">
          <div>{hostname}</div>
          {endpoint && canCopyToClipboard && (
            <div className={copiedToClipboard ? 'tooltip' : ''} data-tip="Copied!">
              <label
                className="btn btn-circle bg-transparent hover:bg-transparent text-base-900 hover:text-blue-400 border-none hover:cursor-pointer"
                onClick={handleCopyToClipboard}
              >
                <RiFileCopyLine className="w-5 h-5 ml-1" />
              </label>
            </div>
          )}
        </div>
        <div className="col-span-1">{region}</div>
        <div className="col-span-1">
          <ClusterStatus status={status} />
        </div>
        <div className="col-span-1 flex justify-end">
          {(status === 'suspended' || status === 'provisioning') && (
            <Link to="#">
              <div className="flex text-gray-400">
                <div className="mr-2">Explore</div>
                <div className="flex flex-col justify-center items-center">
                  <BsArrowRight />
                </div>
              </div>
            </Link>
          )}
          {status === 'failed' && (
            <DeleteCluster
              organizationId={organization_id!}
              workspaceId={workspace_id!}
              cluster={cluster}
            />
          )}
          {(status === 'success' || status === 'sleeping' || status === 'starting') && (
            <Link to={`cluster/${cluster.id}/overview`}>
              <div className="flex text-primary" onClick={handleExplore}>
                <div className="mr-2">Explore</div>
                <div className="flex flex-col justify-center items-center">
                  <BsArrowRight />
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
