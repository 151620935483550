export function checkIfClipboardAPI() {
  if ('clipboard' in navigator) {
    return true;
  }
  return false;
}

export async function copyToClipboard(text: string) {
  if (checkIfClipboardAPI()) {
    return await navigator.clipboard.writeText(text);
  } else {
    throw new Error('Clipboard API not found');
  }
}
