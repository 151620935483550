import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiCheck } from 'react-icons/fi';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem
} from '@/components/ui/select';
import { Spinner } from '@/components/ui/spinner';
import { useToast } from '@/components/ui/use-toast';

import { useCreateWorkspace } from '../api/WorkspaceApi';

const formSchema = z.object({
  workspaceName: z.string().min(3, {
    message: 'Workspace name must be at lest 3 characters.'
  }),
  description: z.string().min(4, {
    message: 'Description must be at lest 4 characters.'
  }),
  cloud: z.string({
    required_error: 'Please select cloud.'
  }),
  region: z.string({
    required_error: 'Please select region.'
  })
});

type CreateWorkspaceFormProps = {
  organizationId: string;
  cloudProviders: any;
};

export default function CreateWorkspaceForm({
  organizationId,
  cloudProviders
}: CreateWorkspaceFormProps) {
  const [selectedCloud, setSelectedCloud] = useState('');
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const createWorkspaceMutation = useCreateWorkspace({
    organizationId,
    config: {
      onSuccess: () => {
        navigate(`/${organizationId}`);
        queryClient.invalidateQueries(['workspaces']);
        toast({
          title: 'Success',
          description: 'Workspace Created'
        });
      }
    }
  });

  const activeCloud = cloudProviders.find((ac: any) => ac.name === selectedCloud);
  const regions = activeCloud?.regions || [];

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      workspaceName: '',
      description: '',
      cloud: '',
      region: ''
    }
  });
  function onSubmit(values: z.infer<typeof formSchema>) {
    const data = {
      name: values.workspaceName,
      description: values.description,
      cloud: values.cloud,
      region: values.region
    };
    createWorkspaceMutation.mutate(data);
  }
  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="workspaceName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="workspace" {...field} />
              </FormControl>
              <FormDescription>Name for your workspace</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Input placeholder="workspace" {...field} />
              </FormControl>
              <FormDescription>Description for your workspace</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="cloud"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Cloud provider</FormLabel>
              <Select
                onValueChange={(v) => {
                  field.onChange(v);
                  setSelectedCloud(v);
                }}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select cloud" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {cloudProviders.map((c: any) => (
                    <SelectItem value={c.name} key={c.name}>
                      {c.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormDescription>Select Cloud provider.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="region"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Region</FormLabel>
              <Select disabled={!(regions.length > 0)} onValueChange={field.onChange}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select region" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {regions.map((r: any) => (
                    <SelectItem value={r.name} key={r.name}>
                      {r.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormDescription>Select region.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="w-full flex justify-end gap-2">
          {createWorkspaceMutation.isLoading && (
            <Button className="w-36" disabled>
              <Spinner variant={'button'} />
            </Button>
          )}
          {createWorkspaceMutation.isSuccess && (
            <Button className="w-36 pointer-events-none">
              <FiCheck />
            </Button>
          )}
          {createWorkspaceMutation.isError && (
            <Button variant={'destructive'} disabled className="w-36">
              x
            </Button>
          )}
          {createWorkspaceMutation.isIdle && <Button type="submit">Create Workspace</Button>}
        </div>
      </form>
    </Form>
  );
}
