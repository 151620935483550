import { useMutation, useQuery } from 'react-query';

import { axios } from '@/shared/utils/axios';
import { ClusterApiProps } from '@/types';

import { ClusterCredential } from '../types';

export async function saveClusterCredential(
  workspaceId: string,
  clusterId: string,
  organizationsId: string,
  clusterCredentials: ClusterCredential
) {
  const res = await axios.post(
    `/organizations/${organizationsId}/workspaces/${workspaceId}/clusters/${clusterId}/credentials`,
    clusterCredentials
  );
  return res.data;
}

export async function executeQuery(
  workspaceId: string,
  clusterId: string,
  organizationId: string,
  preferredCredentialId: string,
  query: string
) {
  const res = await axios.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/queries?credential_id=${preferredCredentialId}`,
    { query }
  );
  return res.data;
}

export async function explainQuery(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  preferredCredentialId: string,
  query: string
) {
  const res = await axios.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/queries/explain?credential_id=${preferredCredentialId}`,
    { query }
  );
  return res.data;
}

type UseDBQueryOptions = {
  config: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
  credentialId: string;
};

type IQueryMutation = {
  query: string;
  explain: boolean;
};

export function useDBQuery({
  config,
  organizationId,
  workspaceId,
  clusterId,
  credentialId
}: UseDBQueryOptions) {
  return useMutation({
    ...config,
    mutationFn: ({ query, explain }: IQueryMutation) => {
      if (explain) {
        return explainQuery(organizationId, workspaceId, clusterId, credentialId, query);
      }
      return executeQuery(organizationId, workspaceId, clusterId, credentialId, query);
    }
  });
}

export async function exportQuery(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  preferredCredentialId: string,
  query: string,
  format: string
) {
  const res = await axios.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/queries/download?credential_id=${preferredCredentialId}`,
    { query, format }
  );
  return res.data;
}

interface IExportQueryMutationVariables {
  query: string;
  format: string;
}

type UseExportQueryOptions = {
  config: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
  credentialId: string;
};

export function useExportQuery({
  config,
  organizationId,
  workspaceId,
  clusterId,
  credentialId
}: UseExportQueryOptions) {
  return useMutation({
    ...config,
    mutationFn: ({ query, format }: IExportQueryMutationVariables) =>
      exportQuery(organizationId, workspaceId, clusterId, credentialId, query, format)
  });
}

export async function getCustomCompletionData({
  organizationId,
  workspaceId,
  clusterId
}: ClusterApiProps) {
  const res = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/metadata/completions`
  );
  return res.data;
}

type UseCustomCompletionOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useCustomCompletion({
  config,
  organizationId,
  workspaceId,
  clusterId
}: UseCustomCompletionOptions) {
  return useQuery({
    ...config,
    queryKey: 'completions',
    queryFn: () => getCustomCompletionData({ organizationId, workspaceId, clusterId })
  });
}
