import React from 'react';
import { useParams } from 'react-router-dom';

import { Spinner } from '@/components/ui/spinner';
import { useOrganizationSubscriptionValidity } from '@/pages/Organization/api/OrganizationApi';
import Header from '@/shared/components/Header/Header';
import { calculateDaysBetweenDates } from '@/shared/utils';

import ExpiryBanner from './ExpiryBanner';

type Props = {
  children: React.ReactNode;
};

export default function GenericDashboardLayout({ children }: Props) {
  const { organization_id } = useParams();
  const { isError, isLoading, data } = useOrganizationSubscriptionValidity(organization_id!);
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <Spinner size={'lg'} />
      </div>
    );
  }
  if (isError) {
    return <div>Error</div>;
  }
  let isExpired, numberOfDaysRemaining;
  if (data?.end_date) {
    numberOfDaysRemaining = calculateDaysBetweenDates(new Date(), new Date(data?.end_date));
    isExpired = numberOfDaysRemaining <= 0;
  }

  const showBanner = data?.show_banner;
  return (
    <div className="grid grid-rows-16 grid-cols-16 h-screen">
      {showBanner && <ExpiryBanner isExpired={isExpired!} daysRemaining={numberOfDaysRemaining} />}
      <div className="row-span-1 col-span-full">
        <Header />
      </div>
      <main className="row-span-15 col-span-full bg-background">{children}</main>
    </div>
  );
}
