import React from 'react';
import { Link } from 'react-router-dom';

export interface BreadCrumpItem {
  title: string;
  internalRedirectionLink: string;
}
interface BreadCrumpProps {
  config?: BreadCrumpItem[];
}
export function BreadCrump({ config }: BreadCrumpProps) {
  return (
    <div className="text-sm text-gray-500">
      {config?.map((item, index) => (
        <React.Fragment key={index}>
          <Link to={item.internalRedirectionLink} className="hover:underline hover:decoration-2">
            {item.title}
          </Link>
          {index !== config.length - 1 ? ' > ' : ''}
        </React.Fragment>
      ))}
    </div>
  );
}
