import { useQueryClient } from 'react-query';

import { useToast } from '@/components/ui/use-toast';
import { API_URL } from '@/shared/config';
import { axios } from '@/shared/utils';

type TotpProps = {
  enabled: boolean;
};

export default function Totp({ enabled }: TotpProps) {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  async function setupTotp() {
    toast({
      title: 'Sending TOTP email ...'
    });
    const response = await axios.put(`${API_URL}/account/totp`);
    if (response.status === 200) {
      toast({
        title: 'TOTP email sent with instructions'
      });
    } else {
      toast({
        title: 'Error setting up TOTP',
        variant: 'destructive'
      });
    }
  }

  async function removeTotp() {
    toast({
      title: 'Removing TOTP'
    });
    const response = await axios.delete(`${API_URL}/account/totp`);
    if (response.status === 200) {
      toast({
        title: 'TOTP Removed'
      });
      queryClient.invalidateQueries('user-details');
    } else {
      toast({
        title: 'Error',
        description: 'Error removing TOTP',
        variant: 'destructive'
      });
    }
  }

  if (enabled) {
    return (
      <div className="w-full" onClick={removeTotp}>
        Remove Totp
      </div>
    );
  }

  return (
    <div className="w-full" onClick={setupTotp}>
      Setup Totp
    </div>
  );
}
