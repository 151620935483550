import React from 'react';
import { useParams } from 'react-router-dom';

import { Spinner } from '@/components/ui/spinner';

import { useLogs } from './api/LogsApi';
import LogsTable from './components/LogTable';

export default function Logs() {
  const { workspace_id, cluster_id, organization_id } = useParams();

  const logsQuery = useLogs({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!
  });

  if (logsQuery.isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full bg-background">
        <Spinner />
      </div>
    );
  }

  if (logsQuery.isError) {
    return <div className="flex items-center justify-center flex-1 bg-base-200">error</div>;
  }

  if (logsQuery.data === null) {
    return (
      <div className="flex items-center justify-center w-full h-3/4">
        <div>No logs created</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1 overflow-x-auto bg-background">
      <div className="flex justify-between px-2 pt-4 pb-9">
        <h1 className="font-bold">Logs</h1>
      </div>
      <LogsTable data={logsQuery.data} />
    </div>
  );
}
