import { useTheme } from '@/components/theme-provider';

export default function ThemeSelection() {
  const { theme, setTheme } = useTheme();
  function handleClick() {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  }
  return (
    <div className="w-full" onClick={handleClick}>
      {theme === 'light' ? 'Toggle Dark Mode' : 'Toggle Light Mode'}
    </div>
  );
}
