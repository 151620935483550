import { useParams } from 'react-router-dom';

import { Spinner } from '@/components/ui/spinner';
import GenericDashboardLayout from '@/layouts/GenericDashboardLayout';

import { useAvailableClouds } from './api/CloudProviderApi';
import CreateWorkspaceForm from './components/CreateWorkspaceForm';

export default function CreateWorkspace() {
  const { organization_id } = useParams();
  const cloudProviders: any = useAvailableClouds({ organizationId: organization_id! });

  if (cloudProviders.isLoading) {
    return (
      <GenericDashboardLayout>
        <div className="flex justify-center items-center h-3/4">
          <Spinner size={'lg'} />
        </div>
      </GenericDashboardLayout>
    );
  }
  if (cloudProviders.isError) {
    return (
      <GenericDashboardLayout>
        <div>{cloudProviders.error.message}</div>
      </GenericDashboardLayout>
    );
  }
  if (cloudProviders.data)
    return (
      <GenericDashboardLayout>
        <div className="p-4 flex items-center flex-col w-full">
          <div className="max-w-md w-full">
            <div className="text-xl font-bold py-4 w-full px-2">Create Workspace</div>
            <div className="border-b-2 border-border h-1 w-full mb-3"></div>
            <div className="w-full">
              <CreateWorkspaceForm
                organizationId={organization_id!}
                cloudProviders={cloudProviders.data}
              />
            </div>
          </div>
        </div>
      </GenericDashboardLayout>
    );
}
