import { createColumnHelper } from '@tanstack/react-table';

import DataTable from '@/components/ui/datatable';
import { formatTime } from '@/shared/utils';

import { FormatSchedule } from '../utils';

import { StatusBadge } from './BackupStatusBadget';
import RestoreBackup from './RestoreBackup';

const columnHelper = createColumnHelper<any>();

const columns = [
  columnHelper.accessor('schedule', {
    header: () => 'SCHEDULE',
    cell: (cell) => <FormatSchedule schedule={cell.getValue()} />
  }),
  columnHelper.accessor('last_run', {
    header: () => 'LAST RUN',
    cell: (cell) => (cell.getValue() === null ? '-' : formatTime(cell.getValue()))
  }),
  columnHelper.accessor('last_run_status', {
    header: () => 'LAST RUN STATUS',
    cell: (cell) => <StatusBadge badgeStatus={cell.getValue()} />
  }),
  columnHelper.accessor('last_successful_run', {
    header: () => 'LAST SUCCESSFUL RUN',
    cell: (cell) => (cell.getValue() === null ? '-' : formatTime(cell.getValue()))
  }),
  columnHelper.accessor('created_on', {
    header: () => 'CREATED ON',
    cell: (cell) => formatTime(cell.getValue())
  }),
  columnHelper.accessor('created_by', {
    header: () => 'CREATED BY'
  }),
  columnHelper.display({
    header: () => 'Actions',
    id: 'actions',
    cell: ({ row }) => {
      return <RestoreBackup backupId={row.original.id} />;
    }
  })
];

// log
// delete
// edit schedule

type DefaultBackupsTableProps = {
  data: any;
};

export default function DefaultBackupsTable({ data }: DefaultBackupsTableProps) {
  return <DataTable columns={columns} data={data} />;
}
