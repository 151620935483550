import { useMutation, useQuery } from 'react-query';

import { axios } from '@/shared/utils/axios';
import { ClusterApiProps } from '@/types';

import { INewK8sCluster, IExistingCluster } from '../types';

async function getClusters(orgId: string, workspaceId: string) {
  const res = await axios.get(`/organizations/${orgId}/workspaces/${workspaceId}/clusters`);
  return res.data;
}

type UseClustersOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
};

export function useClusters({ config, organizationId, workspaceId }: UseClustersOptions) {
  return useQuery({
    ...config,
    queryKey: ['clusters'],
    queryFn: () => getClusters(organizationId, workspaceId)
  });
}

async function getCluster(orgId: string, workspaceId: string, clusterId: string) {
  const res = await axios.get(
    `/organizations/${orgId}/workspaces/${workspaceId}/clusters/${clusterId}`
  );
  return res.data;
}

type UseClusterOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useCluster({ config, organizationId, workspaceId, clusterId }: UseClusterOptions) {
  return useQuery({
    ...config,
    queryKey: ['cluster', clusterId],
    queryFn: () => getCluster(organizationId, workspaceId, clusterId)
  });
}

async function createCluster(
  orgId: string,
  workspaceId: string,
  formData: INewK8sCluster | IExistingCluster
) {
  const res = await axios.post(
    `/organizations/${orgId}/workspaces/${workspaceId}/clusters`,
    formData
  );
  return res.data;
}

type UseCreateClusterOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
};

export function useCreateCluster({ config, organizationId, workspaceId }: UseCreateClusterOptions) {
  return useMutation({
    ...config,
    mutationFn: (formData: INewK8sCluster) => createCluster(organizationId, workspaceId, formData)
  });
}

async function deleteCluster(orgId: string, workspaceId: string, clusterId: number) {
  const res = await axios.delete(
    `/organizations/${orgId}/workspaces/${workspaceId}/clusters/${clusterId}`
  );
  return res.data;
}

type UseDeleteClusterOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
};

export function useDeleteCluster({ config, organizationId, workspaceId }: UseDeleteClusterOptions) {
  return useMutation({
    ...config,
    mutationFn: (clusterId: number) => deleteCluster(organizationId, workspaceId, clusterId)
  });
}

async function getClusterSizes(organizationId: string) {
  const res = await axios.get(`organizations/${organizationId}/cluster-sizes`);
  return res.data;
}

type UseClusterSizesOptions = {
  config?: object;
  organizationId: string;
};

export function useClusterSizes({ config, organizationId }: UseClusterSizesOptions) {
  return useQuery({
    ...config,
    queryKey: ['cluster-sizes'],
    queryFn: () => getClusterSizes(organizationId)
  });
}

export async function changeClusterStatus({
  organizationId,
  workspaceId,
  clusterId,
  status
}: ClusterApiProps & { status: string }) {
  const res = await axios.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/status`,
    { status }
  );
  return res.data;
}

type UseClusterStatusOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useClusterStatusMutation({
  config,
  organizationId,
  workspaceId,
  clusterId
}: UseClusterStatusOptions) {
  return useMutation({
    ...config,
    mutationFn: ({ status }: { status: string }) =>
      changeClusterStatus({ organizationId, clusterId, workspaceId, status })
  });
}
