import { useQuery } from 'react-query';

import { axios } from '@/shared/utils/axios';

export async function getPlansList() {
  const res = await axios.get('/tiers');
  return res.data;
}

type UsePlansListOptions = {
  config?: object;
};

export function usePlansList({ config }: UsePlansListOptions = {}) {
  return useQuery({
    ...config,
    queryKey: ['plans-list'],
    queryFn: getPlansList
  });
}
