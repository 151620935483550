import { useParams } from 'react-router-dom';

import AddressForm from './components/AddressForm';

export default function UpdateAddress() {
  const { organization_id } = useParams();
  return (
    <div className="p-4 flex items-center flex-col w-full">
      <div className="max-w-4xl w-full">
        <div className="text-xl font-bold py-4 w-full px-2">Update Address</div>
        <div className="border-b-2 border-border h-1 w-full mb-3"></div>
        <div className="w-full">
          <AddressForm organizationId={organization_id!} />
        </div>
      </div>
    </div>
  );
}
