import { BsPlayFill } from 'react-icons/bs';

import { Button } from '@/components/ui/button';

type PanelProps = {
  submitting: boolean;
  onExecute: any;
  onExplain: any;
};

export default function QueryEditorTopPanel({ submitting, onExecute, onExplain }: PanelProps) {
  return (
    <div className="flex py-2 px-4 justify-end items-center flex-wrap">
      <div className="flex items-center">
        <div className="" data-tip="shift+ctrl+enter">
          <Button
            variant={'secondary'}
            className={
              'btn-sm w-[104px] mr-2' + (submitting ? ' opacity-50 cursor-not-allowed' : '')
            }
            onClick={onExplain}
          >
            Explain
          </Button>
        </div>
        <div className="" data-tip="ctrl+enter">
          <Button
            className={
              'btn-sm flex items-center w-[104px]' +
              (submitting ? ' opacity-50 cursor-not-allowed' : '')
            }
            onClick={onExecute}
          >
            <BsPlayFill className="mr-2" />
            Run
          </Button>
        </div>
      </div>
    </div>
  );
}
