import createFastContext from '@/shared/utils/CreateFastReactContext';

export interface IQueryEditorStore {
  query: string;
  result: any;
  explain: boolean;
}
/**
 * This store context will be used to store the
 * query
 */
const { Provider, useStore } = createFastContext<IQueryEditorStore>({
  query: '',
  result: null,
  explain: false
});

export { Provider, useStore };
