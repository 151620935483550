import { useParams } from 'react-router-dom';

import GenericDashboardLayout from '@/layouts/GenericDashboardLayout';

import CreateClusterForm from './components/CreateClusterForm';

export default function CreateCluster() {
  const { organization_id, workspace_id } = useParams();
  return (
    <GenericDashboardLayout>
      <div className="p-4 flex items-center flex-col w-full h-full overflow-auto">
        <div className="max-w-md w-full">
          <div className="text-xl font-bold py-4 w-full px-2">Create Cluster</div>
          <div className="border-b-2 border-border h-1 w-full mb-3"></div>
          <div className="w-full">
            <CreateClusterForm organizationId={organization_id!} workspaceId={workspace_id!} />
          </div>
        </div>
      </div>
    </GenericDashboardLayout>
  );
}
