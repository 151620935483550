import React from 'react';

export default function Spinner() {
  return (
    <div data-testid="spinner" className="grid place-content-center w-full h-full">
      <div className="flex items-center gap-2 text-gray-500">
        <SpinnerIcon size={6} />
        loading...
      </div>
    </div>
  );
}

export function SpinnerIcon({ size }: { size: number }) {
  return (
    <span
      className={`h-${size} w-${size} block rounded-full border-4 border-t-gray-800 animate-spin`}
    ></span>
  );
}
