import React from 'react';
import '@/shared/components/elements/Menu/styles.css';
import { AiOutlineUser } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { useUserDetails } from '@/shared/api/InitApis';
import { GetPermissionNode } from '@/shared/utils';

import Logout from './Logout';
import NDA from './NDA';
import ResetPassword from './ResetPassword';
import ThemeSelection from './ThemeSelection';
import Totp from './Totp';

type Props = {
  changeOrgOption: boolean;
};

export default function ProfileDropDown({ changeOrgOption }: Props) {
  const navigate = useNavigate();

  const { organization_id } = useParams();
  const userDetails = useUserDetails({ organizationId: organization_id! });

  let canManageSettings = false;
  if (userDetails.data) {
    const permRoot = new Map(Object.entries(userDetails.data?.permissions));
    const organizationPerms = GetPermissionNode(permRoot, ['organization', 'details']);
    canManageSettings = organizationPerms?.has('edit');
  }

  const isTotpEnabled = userDetails?.data?.totp_enabled;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="rounded-full flex justify-center items-center bg-[#F0F3F7]">
          <AiOutlineUser className="w-5 h-5 m-2 " />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        {canManageSettings && (
          <DropdownMenuItem
            onClick={() => navigate(`/${organization_id}/settings/general-profile`)}
          >
            Settings
          </DropdownMenuItem>
        )}
        {changeOrgOption && (
          <DropdownMenuItem onClick={() => navigate('/')}>Change Organization</DropdownMenuItem>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <Totp enabled={isTotpEnabled} />
        </DropdownMenuItem>
        <DropdownMenuItem>
          <NDA />
        </DropdownMenuItem>
        <DropdownMenuItem>
          <ResetPassword />
        </DropdownMenuItem>
        <DropdownMenuItem>
          <ThemeSelection />
        </DropdownMenuItem>
        <DropdownMenuItem>
          <Logout />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
