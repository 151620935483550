import createFastContext from '@/shared/utils/CreateFastReactContext';

export interface ISpinnerStore {
  show: boolean;
}
/**
 * This store context will be used to store the
 * global spinner boolean
 */
const { Provider, useStore } = createFastContext<ISpinnerStore>({
  show: false
});

export function useSpinnerHooks() {
  const [, setSpinnerStore] = useStore();

  const showSpinner = () => {
    setSpinnerStore({ show: true });
  };

  const hideSpinner = () => {
    setSpinnerStore({ show: false });
  };

  return { showSpinner, hideSpinner };
}

export { Provider, useStore };
