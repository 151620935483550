/* eslint-disable quote-props */
import React from 'react';
import { FiGlobe } from 'react-icons/fi';
import { HiArrowRight } from 'react-icons/hi';
import { useParams, Link } from 'react-router-dom';

import { useCluster } from '@/pages/ClusterList/api/ClusterApi';
import ClusterStatus from '@/pages/ClusterList/components/ClusterStatus';
import { ICluster } from '@/pages/ClusterList/types';
import CopyToClipboardButton from '@/pages/ClusterSettings/components/CopyToClipboardButton';
import Spinner from '@/shared/components/elements/Spinner/Spinner';
import { formatDate } from '@/shared/utils/dayjs';

import ConnectionDialog from '../ClusterSettings/components/ConnectionDialog';

export default function ClusterOverview() {
  const { organization_id, workspace_id, cluster_id } = useParams();
  const { data, isError, isLoading } = useCluster({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <div>Unable to fetch cluster details</div>;
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <OverviewHeader clusterName={data.name} />
        <ConnectionDialog />
      </div>
      <OverviewBody cluster={data} />
    </div>
  );
}

type Props = {
  clusterName: string;
};
function OverviewHeader({ clusterName }: Props) {
  return (
    <div className="flex justify-between px-2 pt-4 pb-9">
      <h1 className="font-bold">{clusterName}</h1>
    </div>
  );
}

type OverviewBodyProps = {
  cluster: ICluster;
};

type ISampleDataStatus = Record<string, string>;

const SampleDataStatus: ISampleDataStatus = {
  inprogress: 'In Progress',
  created: 'Not Started',
  completed: 'Completed',
  failed: 'Failed'
};

function OverviewBody({ cluster }: OverviewBodyProps) {
  const overviewItems: OverviewItemProps[] = [
    { title: 'Hostname', value: cluster.endpoint, clipboard: true },
    { title: 'Version', value: cluster.clickhouse_version ? cluster.clickhouse_version : 'NA' },
    { title: 'Size', value: cluster.cluster_size },
    { title: 'Created on', value: cluster.created_on ? formatDate(cluster.created_on) : 'NA' },
    { title: 'Updated on', value: cluster.updated_on ? formatDate(cluster.updated_on) : 'NA' }
  ];

  if (cluster?.data_load_status) {
    const key: string = cluster.data_load_status;
    overviewItems.push({ title: 'Sample Data', value: SampleDataStatus[key] });
  }

  return (
    <div className="border flex flex-col px-5 pt-7 pb-14 border-border bg-card">
      <Status cluster={cluster} />
      <div className="flex justify-between px-3 py-4 flex-wrap text-sm">
        {overviewItems.map((item, i) => (
          <OverviewItem key={i} title={item.title} value={item.value} clipboard={item.clipboard} />
        ))}
        <div className="flex flex-col text-sm">
          <div className="text-foreground py-3">Backups</div>
          {cluster?.status === 'success' ? (
            <div className="flex flex-1 justify-center">
              <Link className="flex text-primary items-center" to={'../backups'} relative={'path'}>
                <div className="pr-1">View Backups</div>
                <HiArrowRight />
              </Link>
            </div>
          ) : (
            <div className="flex flex-1 justify-center">
              <div className="flex text-gray-400 items-center">
                <div className="pr-1">View Backups</div>
                <HiArrowRight />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

type OverviewItemProps = {
  title: string;
  value: string;
  clipboard?: boolean;
};

function OverviewItem({ title, value, clipboard }: OverviewItemProps) {
  return (
    <div className={`flex ${title === 'Hostname' ? 'flex-1.5' : 'flex-1'} flex-col`}>
      <div className="text-base-600 py-3">{title}</div>
      <div className="flex flex-1">
        <div className="flex items-center">{value}</div>
        {clipboard ? (
          <div className="flex-1 justify-end">
            <CopyToClipboardButton
              content={value}
              buttonClass="btn btn-circle"
              iconClass="w-5 h-5 ml-2"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

function Status({ cluster }: { cluster: ICluster }) {
  return (
    <div className="flex items-center">
      <div className="flex justify-center items-center rounded-lg p-2 text-success-200 w-fit">
        <ClusterStatus status={cluster.status} />
      </div>
      <div className="flex items-center pl-24">
        <FiGlobe className="text-primary-150" />
        <div className="pl-2">{cluster?.region}</div>
      </div>
    </div>
  );
}
