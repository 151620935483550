import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';

import { useClusterUsers } from './api/ClusterUsersApi';
import ClusterUserTable from './components/ClusterUserTable';

export default function ClusterUsers() {
  const { workspace_id, cluster_id, organization_id } = useParams();
  const navigate = useNavigate();

  const { error, isLoading, data } = useClusterUsers({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!
  });

  if (isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center bg-base-200">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div className="flex flex-1 items-center justify-center bg-base-200">Error</div>;
  }

  function handleCreateClusterUser() {
    navigate('create');
  }

  if (!data || data.length === 0) {
    return (
      <div className="flex flex-col flex-1 bg-base-200 overflow-x-auto">
        <div className="flex justify-between px-2 pt-4 pb-9">
          <h1 className="font-bold">Cluster Users</h1>
          <Button onClick={handleCreateClusterUser}>Create User</Button>
        </div>
        <div className="flex flex-1 flex-grow items-center justify-center bg-base-200">
          <div>No Users created</div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1 bg-base-200 overflow-x-auto">
      <div className="flex justify-between px-2 pt-4 pb-9">
        <h1 className="font-bold">Cluster Users</h1>
        <Button onClick={handleCreateClusterUser}>Create Cluster User</Button>
      </div>
      <ClusterUserTable data={data} />
    </div>
  );
}
