import { useQuery } from 'react-query';

import { axios } from '@/shared/utils/axios';

async function getBackups(organizationId: string, workspaceId: string, clusterId: string) {
  const res = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/backups`
  );
  return res.data;
}

type UseBackupsOptions = {
  config: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useBackups({ config, organizationId, workspaceId, clusterId }: UseBackupsOptions) {
  return useQuery({
    ...config,
    queryKey: ['backups', workspaceId, clusterId],
    queryFn: () => getBackups(organizationId, workspaceId, clusterId)
  });
}
