import { createColumnHelper } from '@tanstack/react-table';

import DataTable from '@/components/ui/datatable';

export type User = {
  name: string;
  username: string;
  role: string;
};

const columnHelper = createColumnHelper<User>();

const columns = [
  columnHelper.accessor('name', {
    header: () => 'User Name'
  }),
  columnHelper.accessor('username', {
    header: () => 'Email'
  }),
  columnHelper.accessor('role', {
    header: () => 'Role'
  })
];

type UserTableProps = {
  data: any;
};

export default function UserTable({ data }: UserTableProps) {
  return <DataTable columns={columns} data={data} />;
}
