import React from 'react';
import { z } from 'zod';

import { DataTableColumn } from '@/shared/components/DataTable/DataTable';
import { formatDate } from '@/shared/utils/dayjs';
import { FormItem, InputType } from '@/types/forms';

export const UpdateAdminAddressSchema = z.object({
  admin_email: z
    .string({ required_error: 'Email is required' })
    .min(1, 'Email is required')
    .email('Must be a valid email.'),
  admin_street: z.string({ required_error: 'Street is required' }).min(1, 'Street is required'),
  admin_city: z.string({ required_error: 'City is required' }).min(1, 'City is required'),
  admin_state: z.string({ required_error: 'State is required' }).min(1, 'State is required'),
  admin_zip_code: z.string({ required_error: 'Zip is required' }).min(1, 'Zip is required'),
  admin_country: z.string({ required_error: 'Country is required' }).min(1, 'Country is required')
});

export const UpdateBillingAddressSchema = z.object({
  billing_email: z
    .string({ required_error: 'Email is required' })
    .min(1, 'Email is required')
    .email('Must be a valid email.'),
  billing_street: z.string({ required_error: 'Street is required' }).min(1, 'Street is required'),
  billing_city: z.string({ required_error: 'City is required' }).min(1, 'City is required'),
  billing_state: z.string({ required_error: 'State is required' }).min(1, 'State is required'),
  billing_zip_code: z.string({ required_error: 'Zip is required' }).min(1, 'Zip is required'),
  billing_country: z.string({ required_error: 'Country is required' }).min(1, 'Country is required')
});

export type UpdateAdminAddressSchemaType = z.infer<typeof UpdateAdminAddressSchema>;
export type UpdateBillingAddressSchemaType = z.infer<typeof UpdateBillingAddressSchema>;

export const UPDATE_ADMIN_ADDRESS_FORM: FormItem[] = [
  {
    name: 'admin_email',
    label: 'Email',
    type: InputType.TEXT,
    defaultValue: '',
    placeholder: 'example@xyz.com'
  },
  {
    name: 'admin_street',
    label: 'Street',
    type: InputType.TEXT,
    defaultValue: '',
    placeholder: 'Street'
  },
  {
    name: 'admin_city',
    label: 'City',
    type: InputType.TEXT,
    defaultValue: '',
    placeholder: 'City'
  },
  {
    name: 'admin_state',
    label: 'State',
    type: InputType.TEXT,
    defaultValue: '',
    placeholder: 'State'
  },
  {
    name: 'admin_zip_code',
    label: 'Zip',
    type: InputType.TEXT,
    defaultValue: '',
    placeholder: 'Zip'
  },
  {
    name: 'admin_country',
    label: 'Country',
    type: InputType.TEXT,
    defaultValue: '',
    placeholder: 'Country'
  }
];

export const UPDATE_BILLING_ADDRESS_FORM: FormItem[] = [
  {
    name: 'billing_email',
    label: 'Email',
    type: InputType.TEXT,
    defaultValue: '',
    placeholder: 'example@xyz.com'
  },
  {
    name: 'billing_street',
    label: 'Street',
    type: InputType.TEXT,
    defaultValue: '',
    placeholder: 'Street'
  },
  {
    name: 'billing_city',
    label: 'City',
    type: InputType.TEXT,
    defaultValue: '',
    placeholder: 'City'
  },
  {
    name: 'billing_state',
    label: 'State',
    type: InputType.TEXT,
    defaultValue: '',
    placeholder: 'State'
  },
  {
    name: 'billing_zip_code',
    label: 'Zip',
    type: InputType.TEXT,
    defaultValue: '',
    placeholder: 'Zip'
  },
  {
    name: 'billing_country',
    label: 'Country',
    type: InputType.TEXT,
    defaultValue: '',
    placeholder: 'Country'
  }
];

export const BILLING_HISTORY_TABLE_COLUMNS: DataTableColumn[] = [
  {
    title: 'Date',
    key: 'date',
    transform: (value: any) => formatDate(value)
  },
  {
    title: 'Tier Name',
    key: 'tier_name'
  },
  {
    title: 'Amount',
    key: 'amount',
    value: (dataItem: any) => {
      return `$${dataItem.amount}`;
    }
  },
  {
    title: 'Status',
    key: 'status'
  },
  {
    title: 'Invoice',
    key: 'download_link',
    value: (dataItem: any) => {
      if (dataItem?.download_link === '') {
        return '-';
      }
      return (
        <a className="text-primary-100" href={dataItem.download_link} download>
          Download
        </a>
      );
    }
  }
];
