import { useMutation, useQuery } from 'react-query';

import { axios } from '@/shared/utils/axios';

import { WorkspaceFormData } from '../types';

async function getWorkspaces(orgId: string) {
  const res = await axios.get(`/organizations/${orgId}/workspaces`);
  return res.data;
}

type UseWorkspacesOptions = {
  config?: object;
  organizationId: string;
};

export function useWorkspaces({ config, organizationId }: UseWorkspacesOptions): any {
  return useQuery({
    ...config,
    queryKey: 'workspaces',
    queryFn: () => getWorkspaces(organizationId)
  });
}

async function getWorkspace(orgId: string, workspaceId: string) {
  const res = await axios.get(`/organizations/${orgId}/workspaces/${workspaceId}`);
  return res.data;
}

type UseWorkspaceOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
};

export function useWorkspace({ config, organizationId, workspaceId }: UseWorkspaceOptions): any {
  return useQuery({
    ...config,
    queryKey: ['workspace', workspaceId],
    queryFn: () => getWorkspace(organizationId, workspaceId)
  });
}

async function createWorkspace(orgId: string, formData: WorkspaceFormData) {
  const res = await axios.post(`/organizations/${orgId}/workspaces`, formData);
  return res.data;
}

type UseCreateWorkspaceOptions = {
  config?: object;
  organizationId: string;
};

export function useCreateWorkspace({ config, organizationId }: UseCreateWorkspaceOptions) {
  return useMutation({
    ...config,
    mutationFn: (formData: WorkspaceFormData) => createWorkspace(organizationId, formData)
  });
}

export async function deleteWorkspace(orgId: string, workspaceId: string) {
  const res = await axios.delete(`/organizations/${orgId}/workspaces/${workspaceId}`);
  return res.data;
}

type UseDeleteWorkspaceOptions = {
  config?: object;
  organizationId: string;
};

export function useDeleteWorkspace({ config, organizationId }: UseDeleteWorkspaceOptions) {
  return useMutation({
    ...config,
    mutationFn: (workspaceId: string) => deleteWorkspace(organizationId, workspaceId)
  });
}

async function updateWorkspace(orgId: string, workspaceId: string, formData: WorkspaceFormData) {
  const res = await axios.put(`/organizations/${orgId}/workspaces/${workspaceId}`, formData);
  return res.data;
}

type UseUpdateWorkspaceOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
};

export function useUpdateWorkspace({
  config,
  organizationId,
  workspaceId
}: UseUpdateWorkspaceOptions) {
  return useMutation({
    ...config,
    mutationFn: (formData: WorkspaceFormData) =>
      updateWorkspace(organizationId, workspaceId, formData)
  });
}
