import React from 'react';

import { Base, BaseButtonProps } from './Base';

export function Primary({
  children,
  className,
  ...props
}: BaseButtonProps & { className?: string; children?: React.ReactNode }) {
  return (
    <Base
      className={
        'btn-primary text-primary-content rounded h-[40px] min-h-0 normal-case text-sm font-medium ' +
        className
      }
      {...props}
    >
      {children}
    </Base>
  );
}

export function Outline({
  children,
  className,
  ...props
}: BaseButtonProps & { className?: string; children?: React.ReactNode }) {
  return (
    <Base
      className={
        'btn-outline rounded h-[40px] min-h-0 normal-case text-sm font-medium ' + className
      }
      {...props}
    >
      {children}
    </Base>
  );
}

export function Secondary({
  children,
  className,
  ...props
}: BaseButtonProps & { className?: string; children?: React.ReactNode }) {
  return (
    <Base
      className={
        'rounded text-base-600 border-0 hover:text-base-600 h-[40px] min-h-0 normal-case text-sm font-medium bg-base-425 hover:bg-base-325 ' +
        className
      }
      {...props}
    >
      {children}
    </Base>
  );
}

export function Link({
  children,
  className,
  ...props
}: BaseButtonProps & { className?: string; children?: React.ReactNode }) {
  return (
    <Base
      className={
        'bg-transparent hover:bg-transparent text-base-900 border-none hover:border-none p-0 hover:text-accent h-[40px] min-h-0 normal-case text-base font-medium ' +
        className
      }
      {...props}
    >
      {children}
    </Base>
  );
}
