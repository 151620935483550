import { ImBin } from 'react-icons/im';
import { useParams } from 'react-router-dom';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog';

import { useDeleteBackupSchedule } from '../api/BackupSchedulesApi';

type DeleteScheduledBackupProps = {
  id: number;
};

export default function DeleteScheduledBackup({ id }: DeleteScheduledBackupProps) {
  const { organization_id, workspace_id, cluster_id } = useParams();
  const deleteBackupMutation = useDeleteBackupSchedule({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!
  });
  function handleClick() {
    deleteBackupMutation.mutate(id);
  }
  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <ImBin className="text-primary-600" size={'1.5rem'} />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete schedule?</AlertDialogTitle>
          <AlertDialogDescription>
            This backup schedule will be deleted permanently. Are you sure?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction className="bg-destructive" onClick={handleClick}>
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
