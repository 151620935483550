import React from 'react';

export default function Unauthorized() {
  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <div className="flex">
        <h1 className="text-6xl">403</h1>
        <div className="divider divider-horizontal"></div>
        <div>
          <h1 className="text-6xl">Unauthorized</h1>
          <h2>You are unauthorized to see the requested page.</h2>
          <div></div>
        </div>
      </div>
    </div>
  );
}
