import { useParams } from 'react-router-dom';

import { Spinner } from '@/components/ui/spinner';

import { useClusterRoles } from './api/ClusterRolesApi';
import CreateClusterUserForm from './components/CreateClusterUserForm';

export default function CreateClusterUser() {
  const { organization_id, workspace_id, cluster_id } = useParams();
  const { data, isError, isLoading } = useClusterRoles({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!
  });
  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>Error</div>;
  }
  return (
    <div className="p-4 flex items-center flex-col w-full">
      <div className="max-w-md w-full">
        <div className="text-xl font-bold py-4 w-full px-2">Create Cluster User</div>
        <div className="border-b-2 border-border h-1 w-full mb-3"></div>
        <div className="w-full">
          <CreateClusterUserForm roles={data} />
        </div>
      </div>
    </div>
  );
}
