export default function getPermissionStructure(role: string) {
  if (role === 'orgAdmin') return organizationAdminPermission;
  else if (role === 'workspaceAdmin') return workspaceAdminPermission;
  else if (role === 'readOnly') return readOnlyUserPermission;
  else throw new Error('Invalid user role');
}

const organizationAdminPermission = {
  '*': null
};

const workspaceAdminPermission = {
  workspaces: {
    '*': null
  }
};

const readOnlyUserPermission = {
  workspaces: {
    '*': {
      clusters: {
        edit: null,
        delete: null,
        query: null,
        metadata: null,
        metrics: null,
        ingest: null
      }
    }
  }
};
