import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';

import { useDeleteCluster } from '../api/ClusterApi';
import { ICluster } from '../types';

type DeleteClusterProps = {
  organizationId: string;
  workspaceId: string;
  cluster: ICluster;
};

export default function DeleteCluster({
  organizationId,
  workspaceId,
  cluster
}: DeleteClusterProps) {
  const [input, setInput] = useState('');
  const queryClient = useQueryClient();
  const deleteClusterMutation = useDeleteCluster({
    organizationId,
    workspaceId,
    config: {
      onSuccess: () => {
        queryClient.invalidateQueries(['clusters', workspaceId]);
        queryClient.invalidateQueries(['user_details']);
      }
    }
  });

  function handleDelete() {
    deleteClusterMutation.mutate(cluster.id);
  }
  function handleChange(e: any) {
    setInput(e.target.value);
  }

  const isDisabled = !(cluster.name === input);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Delete</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Delete Cluster</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete the cluster? This action cannot be undone.
          </DialogDescription>
        </DialogHeader>
        <div className="py-3">
          Please enter <span className="font-semibold">{cluster.name}</span> to confirm.
        </div>
        <Input type="text" placeholder="cluster-xyz" onChange={handleChange} />
        <DialogFooter className="sm:justify-start">
          <Button variant={'destructive'} onClick={handleDelete} disabled={isDisabled}>
            Delete
          </Button>
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
