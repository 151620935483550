import React, { useState } from 'react';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { HiOutlineDatabase } from 'react-icons/hi';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger
} from '@/components/ui/context-menu';
import useClusterPermissions from '@/pages/ClusterDashboard/hooks/useClusterPermissions';
import Spinner from '@/shared/components/elements/Spinner/Spinner';

import { useDatabaseTables } from '../api/MetadataApi';

import { Table } from './Table';

export function Schema({
  schema,
  clusterId,
  workspaceId,
  preferredCredentialId,
  organizationId
}: any) {
  const [showSchema, setShowSchema] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { organization_id, workspace_id, cluster_id } = useParams();

  const clusterPermissions = useClusterPermissions({
    orgnizationId: organizationId!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!
  });

  const {
    data: tables,
    isError,
    isLoading
  } = useDatabaseTables({
    organizationId,
    workspaceId,
    clusterId,
    credentialId: preferredCredentialId,
    schema
  });

  const handleShowSchemaClick = async () => {
    if (showSchema) {
      setShowSchema(false);
    } else {
      await queryClient.invalidateQueries(schema);
      setShowSchema(true);
    }
  };

  function handleBackup(e: any) {
    e.stopPropagation();
    navigate(
      `/${organization_id}/workspace/${workspace_id}/cluster/${cluster_id}/backups/create?db=${schema}`
    );
  }

  if (isLoading || clusterPermissions.isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isError || clusterPermissions.isError) {
    return <div className="flex flex-1 items-center justify-center">Error</div>;
  }

  return (
    <>
      <div className="pl-2 pb-1 w-full cursor-pointer">
        <div onClick={handleShowSchemaClick} className="flex">
          <button>{showSchema ? <FiChevronDown /> : <FiChevronRight />}</button>
          {clusterPermissions.canBackup && (
            <ContextMenu>
              <ContextMenuTrigger className="w-full">
                <div className="flex items-center justify-between flex-1 pl-2">
                  <div className="flex items-center ">
                    <HiOutlineDatabase className="text-primary-600" />
                    <span className="pl-3">{schema}</span>
                  </div>
                </div>
              </ContextMenuTrigger>
              <ContextMenuContent>
                <ContextMenuItem onClick={handleBackup}>Backup</ContextMenuItem>
              </ContextMenuContent>
            </ContextMenu>
          )}
        </div>
        <div>
          {showSchema &&
            tables.map((item: any) => {
              return (
                <Table
                  key={`${schema}.${item.name}`}
                  {...item}
                  clusterId={clusterId}
                  workspaceId={workspaceId}
                  organizationId={organizationId}
                  preferredCredentialId={preferredCredentialId}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}
