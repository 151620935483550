import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { VscCircleLargeFilled } from 'react-icons/vsc';
import { Link } from 'react-router-dom';

import { useStore as useWorkspaceStore } from '@/shared/store/workspace';

import { IWorkspace } from '../types';

import WorkspaceDropDown from './WorkspaceDropdown';

type Props = {
  workspace: IWorkspace;
};

const statusMap: Record<string, any> = {
  active: 'text-success',
  inactive: 'text-error',
  pending: 'text-warning'
};

export function ClusterStatus({ status }: { status: string }) {
  const statusColor = statusMap[status];
  const capitalize = (s: string | any[]) => (s && s[0].toUpperCase() + s.slice(1)) || '';
  if (status) {
    return (
      <div
        className={`flex justify-center items-center px-0 border-0 bg-transparent text-success font-bold p-3 text-base ${statusColor}`}
      >
        <VscCircleLargeFilled className={`w-2 h-2 mr-2 ${statusColor}`} />
        {capitalize(status)}
      </div>
    );
  } else {
    return (
      <div className="badge px-0 border-0 bg-transparent text-warning font-bold p-2 text-base">
        unknown
      </div>
    );
  }
}

export default function WorkspaceBar({ workspace }: Props) {
  const { name, status, cluster_count } = workspace;
  // const { organization_id } = useParams();
  // const user = useUserDetails({ organizationId: organization_id! });
  const [, setWorkspaceStore] = useWorkspaceStore();

  // let canEdit = false;

  // if (user.data) {
  //   const permRoot = new Map(Object.entries(user.data.permissions));
  //   const accessPath = ['workspaces', `id-${workspace.id}`];
  //   const currentPermRoot = GetPermissionNode(permRoot, accessPath);

  //   canEdit = currentPermRoot.has('edit');
  // }

  function setCurrentWorkspace(workspace: IWorkspace) {
    setWorkspaceStore({ workspaceId: workspace.id, workspaceName: workspace.name });
  }

  return (
    <div className="bg-card shadow-sm rounded-md w-full h-14">
      <div className="grid w-full grid-cols-8 h-full px-4 py-2 items-center">
        <h2 className="text-base-900 text-base col-span-2">{name}</h2>
        <div className="col-span-1">Clusters</div>
        <div className="col-span-2">{cluster_count}</div>
        <div className="col-span-1">
          <ClusterStatus status={status} />
        </div>
        <div className="col-span-2 flex justify-end gap-4">
          <Link
            to={`workspace/${workspace.id}`}
            state={{ workspace }}
            onClick={() => setCurrentWorkspace(workspace)}
          >
            <div className="text-primary flex">
              <div className="mr-2">View Clusters</div>
              <div className="flex flex-col justify-center items-center">
                <BsArrowRight />
              </div>
            </div>
          </Link>
          <WorkspaceDropDown workspace={workspace} />
        </div>
      </div>
    </div>
  );
}
