import { RadioGroup } from '@headlessui/react';
import React, { useState } from 'react';

interface ButtonGroupProps {
  items: string[];
  defaultValue?: string;
  onChange: (item: string) => void;
}

export default function ButtonGroup({ items, defaultValue, onChange }: ButtonGroupProps) {
  const [selected, setSelected] = useState(defaultValue ?? items[0]);

  const selectItem = (item: string) => {
    setSelected(item);
    onChange(item);
  };
  return (
    <RadioGroup value={selected} onChange={selectItem}>
      <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
      <div className="flex border border-base-410 rounded">
        {items.map((item, index) => (
          <RadioGroup.Option
            key={item}
            value={item}
            className={({ checked }) =>
              `${checked ? 'bg-secondary' : 'bg-transparent'} text-secondary-content relative flex cursor-pointer h-[40px] px-5 py-4 focus:outline-none
               ${index === 0 ? 'rounded-tl rounded-bl' : ''} 
               ${index === items.length - 1 ? 'rounded-tr rounded-br' : ''} 
              `
            }
          >
            {() => (
              <>
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label as="p" className={'font-medium'}>
                        {item}
                      </RadioGroup.Label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
