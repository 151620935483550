import { sql } from '@codemirror/lang-sql';
import ReactCodeMirror from '@uiw/react-codemirror';
import React from 'react';
import { useParams } from 'react-router-dom';

import { IQueryEditorStore, useStore as useQueryStore } from '@/shared/store/query-editor';

import './SQLEditor.scss';
import { useCustomCompletion } from '../api/QueryEditorApi';

import { ClickHouse } from './ClickhouseDialect';

type ISQLEditorProps = {
  onChange: (newValue: string) => void;
  theme: string;
};

export default function SQLEditor({ onChange, theme }: ISQLEditorProps) {
  const { organization_id, workspace_id, cluster_id } = useParams();
  const [queryEditorStore, setQueryEditorStore] = useQueryStore<IQueryEditorStore>();
  const completionData = useCustomCompletion({
    workspaceId: workspace_id!,
    clusterId: cluster_id!,
    organizationId: organization_id!
  });

  const { query } = queryEditorStore;

  function handleChange(value: any) {
    setQueryEditorStore({ query: value });
    onChange(value);
  }

  function handleSelection(event: any) {
    event.selectionCode && onChange(event.selectionCode);
  }

  const completionmap = completionData?.data?.tables || {};

  return (
    <ReactCodeMirror
      className="z-0 border-y border-base-475 bg-base-400 font-mono text-sm"
      height="30vh"
      id="query-editor"
      basicSetup={{
        lineNumbers: true
      }}
      value={query}
      onChange={handleChange}
      onStatistics={handleSelection}
      extensions={[
        sql({
          dialect: ClickHouse,
          schema: { ...completionmap },
          upperCaseKeywords: false
        })
      ]}
      theme={theme === 'dark' ? 'dark' : 'light'}
    />
  );
}
