import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Spinner } from '@/components/ui/spinner';
import {
  useOrganizationAddressQuery,
  useOrganizationDetails
} from '@/pages/Organization/api/OrganizationApi';

import { DisplayAddress } from './DisplayAddress';

export default function GeneralProfile() {
  const { organization_id } = useParams();
  const navigate = useNavigate();

  const [orgName, setOrgName] = useState<string | undefined>();

  const {
    error: orgDetailsError,
    isLoading: orgDetailsLoading,
    data: orgDetailsData
  } = useOrganizationDetails({ organizationId: organization_id! });
  const {
    error: adminAddressError,
    isLoading: adminAddressLoading,
    data: adminAddressData
  } = useOrganizationAddressQuery(organization_id!, 'admin');
  const {
    error: billingAddressError,
    isLoading: billingAddressLoading,
    data: billingAddressData
  } = useOrganizationAddressQuery(organization_id!, 'billing');

  useEffect(() => {
    if (orgDetailsData?.name) {
      setOrgName(orgDetailsData?.name);
    }
  }, [orgDetailsData]);

  if (orgDetailsLoading || adminAddressLoading || billingAddressLoading) {
    return <Spinner />;
  }

  if (
    orgDetailsError ||
    !orgDetailsData ||
    (adminAddressError && (adminAddressError as any).response.status !== 404) ||
    (billingAddressError && (billingAddressError as any).response.status !== 404)
  ) {
    return <h1>Error</h1>;
  }

  const updateAddress = () => {
    navigate(`/${organization_id}/settings/update-address`);
  };

  return (
    <div>
      {/* Organization name section  */}
      <div>
        <div className="text-base font-bold">General</div>
        <div className="text-xs font-medium text-chista-grey">
          General settings for the organization
        </div>
        <div className="flex items-center my-4">
          <Label className="min-w-[250px] font-bold">Organization Name</Label>
          <Input
            name="organization_name"
            defaultValue={orgName}
            className="max-w-[350px]"
            type="text"
          />
        </div>
      </div>

      <div className="text-base font-bold my-4">Address</div>

      <div className="mb-6">
        <div className="text-sm font-bold mb-3">Admin Address</div>
        <DisplayAddress data={adminAddressData} />
      </div>

      <div className="mb-6">
        <div className="text-sm font-bold mb-3">Billing Address</div>
        <DisplayAddress data={billingAddressData} />
      </div>

      <div className="mt-10 mb-10">
        <Button className="min-w-[120px]" onClick={updateAddress}>
          Update Addresses
        </Button>
      </div>
    </div>
  );
}
