import { ImPencil } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';

type EditClusterUserProps = {
  username: string;
};

export default function EditClusterUser({ username }: EditClusterUserProps) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`edit/${username}`);
  }
  return (
    <div onClick={handleClick} className="cursor-pointer">
      <ImPencil className="text-primary-600" size={'1.5rem'} />
    </div>
  );
}
