import { useUserDetails } from '@/shared/api';
import { GetPermissionNode } from '@/shared/utils';

type UseClusterPermissionsProps = {
  organizationId: string;
  workspaceId: string;
};

export default function useClusterPermissions({
  organizationId,
  workspaceId
}: UseClusterPermissionsProps) {
  const user = useUserDetails({ organizationId: organizationId });

  let quotaRemaining = 0;
  let canCreateCluster = false;
  if (user.data) {
    const permRoot = new Map(Object.entries(user.data?.permissions));
    const clusterPerms = GetPermissionNode(permRoot, [
      'workspaces',
      `id-${workspaceId}`,
      'clusters'
    ]);

    canCreateCluster = clusterPerms?.has('create');

    quotaRemaining = user.data?.quota_remaining.clusters_remaining;
  }

  return {
    isLoading: user.isLoading,
    isError: user.isError,
    canCreateCluster,
    quotaRemaining
  };
}
