import { useQuery } from 'react-query';

import { axios } from '@/shared/utils';

async function getAvailableClouds(orgId: string) {
  const res = await axios.get(`organizations/${orgId}/regions`);
  return res.data;
}

type UseAvailableCloudsOptions = {
  config?: object;
  organizationId: string;
};

export function useAvailableClouds({ config, organizationId }: UseAvailableCloudsOptions) {
  return useQuery({
    ...config,
    queryKey: ['clouds'],
    queryFn: () => getAvailableClouds(organizationId)
  });
}
