import { useQuery } from 'react-query';

import { axios } from '@/shared/utils';

export async function getConnectionDetails(orgId: string, workspaceId: string, clusterId: string) {
  const res = await axios.get(
    `/organizations/${orgId}/workspaces/${workspaceId}/clusters/${clusterId}/connection-details`
  );
  return res.data;
}

type UseConnectionDetails = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useConnectionDetails({
  config,
  organizationId,
  workspaceId,
  clusterId
}: UseConnectionDetails) {
  return useQuery({
    ...config,
    queryKey: ['connection-details'],
    queryFn: () => getConnectionDetails(organizationId, workspaceId, clusterId)
  });
}
