/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import './Header.scss';
import { themeChange } from 'theme-change';

import Logo from '@/assets/chistadata.io.svg';
import { useCluster } from '@/pages/ClusterList/api/ClusterApi';
import { UseOrganizations } from '@/pages/Organization/api/OrganizationApi';
import { useWorkspace } from '@/pages/Workspace/api/WorkspaceApi';

import Help from './Help';
import ProfileDropDown from './ProfileDropDown';

function ChistadataLogo() {
  return (
    <Link to={'/'}>
      <img src={Logo} alt="chistadata-logo" />
    </Link>
  );
}

function Nav({
  organizationId,
  activeCluster,
  activeWorkspace
}: {
  organizationId: string;
  activeWorkspace?: any;
  activeCluster?: any;
}) {
  return (
    <div className="text-base ml-6">
      <ul className="flex gap-2">
        {activeWorkspace && (
          <>
            <li className="">
              <Link
                to={`/${organizationId}/`}
                className="flex justify-center items-center h-full w-full px-2 hover:bg-accent hover:rounded"
              >
                <AiOutlineHome />
              </Link>
            </li>
            <li className="font-thin">/</li>
            <li>
              <Link
                to={`/${organizationId}/workspace/${activeWorkspace.id}`}
                className="flex justify-center items-center h-full w-full px-2 hover:bg-accent hover:rounded"
              >
                {activeWorkspace.name}
              </Link>
            </li>
          </>
        )}
        {activeCluster && (
          <>
            <li className="font-thin">/</li>
            <li>
              <Link
                to={`/${organizationId}/workspace/${activeWorkspace.id}/cluster/${activeCluster.id}/overview`}
                className="flex justify-center items-center h-full w-full px-2 hover:bg-accent hover:rounded"
              >
                {activeCluster.name}
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

type Props = {
  showNav?: boolean;
};

export default function Header({ showNav = true }: Props) {
  const organizationsQuery = UseOrganizations();
  const { organization_id, workspace_id, cluster_id } = useParams();
  const currentWorkspace: any =
    organization_id &&
    workspace_id &&
    useWorkspace({ organizationId: organization_id!, workspaceId: workspace_id! });
  const currentCluster: any =
    workspace_id &&
    cluster_id &&
    useCluster({
      organizationId: organization_id!,
      workspaceId: workspace_id!,
      clusterId: cluster_id!
    });

  useEffect(() => {
    themeChange(false);
  }, []);

  let activeOrg;
  if (organizationsQuery.data) {
    const index = organizationsQuery.data.findIndex(
      (org: any) => org.organization_id === parseInt(organization_id!)
    );
    activeOrg = organizationsQuery.data[index];
  }

  const showChangeOrgOption = Boolean(
    activeOrg && organizationsQuery?.data?.length && organizationsQuery.data.length > 1
  );
  return (
    <div className="justify-between flex items-center z-10 w-full h-full px-[1%] text-base-600 bg-card border-b border-base-410">
      <div className="flex items-end">
        <ChistadataLogo />
        {showNav && (
          <Nav
            organizationId={organization_id ?? ''}
            activeWorkspace={currentWorkspace?.data ?? ''}
            activeCluster={currentCluster?.data ?? ''}
          />
        )}
      </div>
      <div>
        <div className="flex items-center">
          <div className="mr-8 flex justify-center items-center">
            <Help />
          </div>
          <div className="mr-3 text-base-510">
            {activeOrg && <>{activeOrg.organization_name}</>}
          </div>
          <ProfileDropDown changeOrgOption={showChangeOrgOption} />
        </div>
      </div>
    </div>
  );
}
