import { useMutation, useQuery } from 'react-query';

import { axios } from '@/shared/utils';

export async function getBackupSchedules(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  backup_type?: 'user' | 'default'
) {
  const params: any = {};
  if (backup_type) {
    params.backup_type = backup_type;
  }
  const res = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/backup-schedules`,
    {
      params
    }
  );
  return res.data;
}

type UseBackupsSchedulesOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
  backup_type: 'user' | 'default';
};

export function useBackupSchedules({
  config,
  organizationId,
  workspaceId,
  clusterId,
  backup_type
}: UseBackupsSchedulesOptions) {
  return useQuery({
    ...config,
    queryKey: ['backup-schedules', workspaceId, clusterId, backup_type],
    queryFn: () => getBackupSchedules(organizationId, workspaceId, clusterId, backup_type)
  });
}

// Delete Backup
export async function deleteBackupSchedule(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  id: number
) {
  const res = await axios.delete(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/backup-schedules/${id}`
  );
  return res.data;
}

type UseDeleteBackupScheduleOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useDeleteBackupSchedule({
  config,
  organizationId,
  workspaceId,
  clusterId
}: UseDeleteBackupScheduleOptions) {
  return useMutation({
    ...config,
    mutationFn: async (backupId: number) =>
      await deleteBackupSchedule(organizationId, workspaceId, clusterId, backupId)
  });
}

// Edit Backup
interface BackupScheduleEditInput {
  start_from?: string;
  cron_schedule: string;
}

export async function editBackupSchedule(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  id: number,
  backupScheduleEditInput: BackupScheduleEditInput
) {
  const res = await axios.put(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/backup-schedules/${id}`,
    backupScheduleEditInput
  );
  return res.data;
}

type UseEditBackupScheduleOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useEditBackupSchedule({
  config,
  organizationId,
  workspaceId,
  clusterId
}: UseEditBackupScheduleOptions) {
  return useMutation({
    ...config,
    mutationFn: ({
      backupScheduleId,
      backupScheduleEditInput
    }: {
      backupScheduleId: number;
      backupScheduleEditInput: BackupScheduleEditInput;
    }) =>
      editBackupSchedule(
        organizationId,
        workspaceId,
        clusterId,
        backupScheduleId,
        backupScheduleEditInput
      )
  });
}
