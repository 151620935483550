import React from 'react';
import { useParams } from 'react-router-dom';

import { Spinner } from '@/components/ui/spinner';

import { useBackups } from './api/BackupApi';
import BackupsTable from './components/BakupsTable';

export default function Backups() {
  const { workspace_id, cluster_id, organization_id } = useParams();

  const backupsQuery = useBackups({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!,
    config: {
      refetchInterval: 30 * 1000
    }
  });

  if (backupsQuery.isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center bg-background">
        <Spinner />
      </div>
    );
  }

  if (backupsQuery.isError) {
    return <div className="flex flex-1 items-center justify-center bg-base-200">error</div>;
  }

  if (backupsQuery.data === null) {
    return (
      <div className="flex h-3/4 w-full items-center justify-center">
        <div>No backups created</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1 bg-background overflow-x-auto">
      <div className="flex justify-between px-2 pt-4 pb-9">
        <h1 className="font-bold">Backups</h1>
      </div>
      <BackupsTable data={backupsQuery.data} />
    </div>
  );
}
