import { MdDownload } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { useToast } from '@/components/ui/use-toast';
import { SpinnerIcon } from '@/shared/components/elements/Spinner/Spinner';

import { UseLogsPresignedUrl } from '../api/LogsApi';

type DownloadLogProps = {
  objectKey: string;
};

function triggerFileDownload(link: string) {
  const downloadLink = document.createElement('a');
  downloadLink.href = link;
  downloadLink.target = '_blank';
  downloadLink.click();
}

export function DownloadLog({ objectKey }: DownloadLogProps) {
  const { organization_id, workspace_id, cluster_id } = useParams();
  const { toast } = useToast();

  const logsPresignedUrlMutation = UseLogsPresignedUrl({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!,
    key: objectKey,
    config: {
      onSuccess: (url: any) => {
        triggerFileDownload(url);
        toast({
          title: 'Download Log Success'
        });
      },
      onError: () => {
        toast({ title: 'Download Log Failed', variant: 'destructive' });
      }
    }
  });

  function handleDownload() {
    logsPresignedUrlMutation.mutate({ key: objectKey });
  }

  return (
    <div>
      {logsPresignedUrlMutation.isLoading ? (
        <SpinnerIcon size={4} />
      ) : (
        <MdDownload onClick={handleDownload} />
      )}
    </div>
  );
}
