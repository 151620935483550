import { AxiosError } from 'axios';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { usePlansList } from '@/api/tiers/tierApis';
import {
  useBillingHistory,
  useOrganizationActiveSubscriptionQuery,
  useOrganizationAddressQuery,
  useOrganizationDetails,
  useOrganizationSubscriptionLinkMutation,
  useOrganizationSubscriptionValidity
} from '@/pages/Organization/api/OrganizationApi';
import { BILLING_HISTORY_TABLE_COLUMNS } from '@/pages/Settings/config';
import DataTable from '@/shared/components/DataTable/DataTable';
import Spinner from '@/shared/components/elements/Spinner/Spinner';
import { useAlertHooks } from '@/shared/store/alert';
import { calculateAbsoluteDaysBetweenDates, formatDate } from '@/shared/utils/dayjs';

import { SubscriptionCard } from './components/SubscriptionCard';

export default function Billing() {
  const { organization_id } = useParams();
  const { setAlert } = useAlertHooks();
  const navigate = useNavigate();

  const {
    isError: isOrgAddressError,
    error: orgAddressError,
    isLoading: isOrgAddressLoading,
    data: orgAddressData
  } = useOrganizationAddressQuery(organization_id!, 'billing');

  const {
    isError: isOrgError,
    isLoading: isOrgLoading,
    data: orgData
  } = useOrganizationDetails({ organizationId: organization_id! });

  const {
    isError: isActiveSubsError,
    error: activeSubsError,
    isLoading: isActiveSubsLoading,
    data: activeSubsData
  } = useOrganizationActiveSubscriptionQuery(organization_id!);

  const {
    isError: isPlansListError,
    isLoading: isPlansListLoading,
    data: plansListData
  } = usePlansList();

  const {
    isError: isBillingHistoryError,
    isLoading: isBillingHistoryLoading,
    data: billingHistoryData
  } = useBillingHistory(organization_id!);

  const {
    isError: isSubscriptionValidityError,
    isLoading: isSubscriptionValidityLoading,
    data: subscriptionValidityData
  } = useOrganizationSubscriptionValidity(organization_id!);

  const createSubscriptionLinkMutation = useOrganizationSubscriptionLinkMutation(
    organization_id!,
    (data: any) => {
      if (data.url) {
        window.location.replace(data.url);
      }
    },
    (err: AxiosError<{ message: string }>) => {
      const errorMessage = err.response?.data.message;
      if (errorMessage !== undefined) {
        setAlert(errorMessage, 'error');
      }
    }
  );

  if (
    isPlansListLoading ||
    isOrgLoading ||
    isOrgAddressLoading ||
    isActiveSubsLoading ||
    isBillingHistoryLoading ||
    isSubscriptionValidityLoading
  ) {
    return <Spinner />;
  }

  if (
    isPlansListError ||
    isOrgError ||
    isBillingHistoryError ||
    !plansListData ||
    isSubscriptionValidityError ||
    (isActiveSubsError && (activeSubsError as any).response.status !== 404) ||
    (isOrgAddressError && (orgAddressError as any).response.status !== 404) ||
    !subscriptionValidityData
  ) {
    return <h1>Error</h1>;
  }

  let numberOfDaysRemaining: number;
  if (activeSubsData) {
    const activeSubscriptionEndDate = new Date(activeSubsData.end_date);
    const currentDate = new Date();
    numberOfDaysRemaining = calculateAbsoluteDaysBetweenDates(
      activeSubscriptionEndDate,
      currentDate
    );
  }

  const plansList = plansListData.map((plan: any) => {
    plan.tier.isActive = plan.tier.id === orgData?.tier.id;
    plan.tier.isUpgradable =
      plan.tier.available && !plan.tier.isActive && plan.tier.level > (orgData?.tier.level ?? 0);

    if (plan.tier.isActive) {
      if (activeSubsData) {
        plan.tier.startDate = formatDate(activeSubsData.start_date);
      }

      plan.tier.numberOfDaysRemaining = numberOfDaysRemaining;
    }
    return plan;
  });

  const upgradePlan = (tier: string) => {
    // Check if billing address is updated
    if (!orgAddressData) {
      navigate(`/${organization_id}/settings/update-address`);
    }

    // if billing address is updated, naviate to payment gateway
    createSubscriptionLinkMutation.mutate(tier);
  };

  return (
    <>
      <div>
        <div className="text-base font-bold">Subscription Plan</div>

        <div className="flex mt-6 gap-7 flex-wrap">
          {plansList?.map((plan: any, index: number) => (
            <SubscriptionCard
              key={index}
              tier={plan.tier}
              upgradeAction={upgradePlan}
              subscriptionValidityData={subscriptionValidityData}
            />
          ))}
        </div>
      </div>
      <div className="mt-16">
        <div className="text-base font-bold">Billing History</div>
        <div className="mt-6">
          <DataTable
            columns={BILLING_HISTORY_TABLE_COLUMNS}
            data={billingHistoryData}
            containerStyle="max-h-[300px] overflow-auto"
          />
        </div>
      </div>
    </>
  );
}
