import React from 'react';
import { VscCircleLargeFilled } from 'react-icons/vsc';

import { backupStatus } from '../types';

type badgeProps = {
  badgeStatus: backupStatus;
};

export function StatusBadge({ badgeStatus }: badgeProps) {
  if (badgeStatus === backupStatus.success) {
    return (
      <div className="flex items-center border-0 text-success">
        <VscCircleLargeFilled className="w-2 h-2 mr-2 text-success" />
        {badgeStatus}
      </div>
    );
  } else if (badgeStatus === backupStatus.failed) {
    return (
      <div className="flex items-center border-0 text-destructive">
        <VscCircleLargeFilled className="w-2 h-2 mr-2 text-destructive" />
        {badgeStatus}
      </div>
    );
  }
  return (
    <div className="flex items-center border-0 text-background">
      <VscCircleLargeFilled className="w-2 h-2 mr-2 text-background" />
      {badgeStatus}
    </div>
  );
}
