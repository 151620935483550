import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FiCheck } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem
} from '@/components/ui/select';
import { Spinner } from '@/components/ui/spinner';
import { Switch } from '@/components/ui/switch';
import { useToast } from '@/components/ui/use-toast';

import { useCreateCluster } from '../api/ClusterApi';
import { ClusterType } from '../types';

const formSchema = z.object({
  clusterName: z.string().min(4, {
    message: 'Cluster name must be at lest 4 characters.'
  }),
  size: z
    .string({
      required_error: 'Please cluster size.'
    })
    .min(1, {
      message: 'Select a size'
    }),
  password: z.string().min(4, {
    message: 'Password must be at least 4 characters.'
  }),
  sampleDb: z.boolean().default(false).optional(),
  coldStorage: z.boolean().default(false).optional(),
  proxy: z.boolean().default(false).optional()
});

type CreateClusterFormProps = {
  organizationId: string;
  workspaceId: string;
};

export default function CreateClusterForm({ organizationId, workspaceId }: CreateClusterFormProps) {
  const { toast } = useToast();
  const navigate = useNavigate();
  const createClusterMutation = useCreateCluster({
    organizationId,
    workspaceId,
    config: {
      onSuccess: () => {
        navigate(`/${organizationId}/workspace/${workspaceId}`);
        toast({ title: 'Cluster created' });
      },
      onError: () => {
        toast({ title: 'Cluster creation failed', variant: 'destructive' });
      }
    }
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      clusterName: '',
      size: '',
      password: '',
      sampleDb: false,
      coldStorage: false,
      proxy: false
    }
  });
  function onSubmit(values: z.infer<typeof formSchema>) {
    const data = {
      type: ClusterType.ClusterTypeNewK8s,
      name: values.clusterName,
      size: values.size,
      default_user_password: values.password,
      create_sample_database: values.sampleDb || false,
      cold_storage: values.coldStorage ? { type: 's3_internal' } : null,
      deploy_proxy: values.proxy || false
    };
    createClusterMutation.mutate(data);
  }
  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="clusterName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Cluster Name</FormLabel>
              <FormControl>
                <Input placeholder="cluster xyz" {...field} />
              </FormControl>
              <FormDescription>Name for your Cluster</FormDescription>
              <FormMessage></FormMessage>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Default User Password</FormLabel>
              <FormControl>
                <Input placeholder="password" {...field} />
              </FormControl>
              <FormDescription>Default user password</FormDescription>
              <FormMessage></FormMessage>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="size"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Cluster Size</FormLabel>
              <Select onValueChange={field.onChange}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select size" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="nano">nano</SelectItem>
                </SelectContent>
              </Select>
              <FormDescription>Select Cluster size.</FormDescription>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="sampleDb"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
              <div className="space-y-0.5">
                <FormLabel>Add a sample database</FormLabel>
                <FormDescription>Database description</FormDescription>
              </div>
              <FormControl>
                <Switch checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="coldStorage"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
              <div className="space-y-0.5">
                <FormLabel>Enable Cold Storage</FormLabel>
                <FormDescription>ColdS Storage description</FormDescription>
              </div>
              <FormControl>
                <Switch checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="proxy"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
              <div className="space-y-0.5">
                <FormLabel>Enable Proxy</FormLabel>
                <FormDescription>Proxy description</FormDescription>
              </div>
              <FormControl>
                <Switch checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
            </FormItem>
          )}
        />
        <div className="w-full flex justify-end gap-2">
          {createClusterMutation.isLoading && (
            <Button className="w-36" disabled>
              <Spinner variant={'button'} />
            </Button>
          )}
          {createClusterMutation.isSuccess && (
            <Button className="w-36 pointer-events-none">
              <FiCheck />
            </Button>
          )}
          {createClusterMutation.isError && (
            <Button variant={'destructive'} disabled className="w-36">
              x
            </Button>
          )}
          {createClusterMutation.isIdle && <Button type="submit">Create Cluster</Button>}
        </div>
      </form>
    </Form>
  );
}
