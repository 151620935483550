import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { IoAdd } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useOrganizationSubscriptionValidity } from '@/pages/Organization/api/OrganizationApi';

import GenericDashboardLayout from '../../layouts/GenericDashboardLayout';

import { useWorkspaces } from './api/WorkspaceApi';
import WorkspaceBar from './components/WorkspaceBar';
import WorkspacePlanLimitAlert from './components/WorkspacePlanLimitAlert';
import UseWorkspacePermission from './hooks/useWorkspacePermissions';
import { IWorkspace } from './types';

export default function Workspace() {
  const { organization_id } = useParams();
  const navigate = useNavigate();

  const workspacesQuery = useWorkspaces({
    organizationId: organization_id!
  });

  const { isError, isLoading, quotaRemaining, canCreateWorkspace, checkWorkspacePermission } =
    UseWorkspacePermission({
      organizationId: organization_id!
    });

  const {
    isError: isSubscriptionValidityError,
    isLoading: isSubscriptionValidityLoading,
    data: subscriptionValidityData
  } = useOrganizationSubscriptionValidity(organization_id!);

  if (workspacesQuery.isLoading || isLoading || isSubscriptionValidityLoading) {
    return (
      <GenericDashboardLayout>
        <div className="flex justify-center items-center h-3/4">
          <Spinner size={'lg'} />
        </div>
      </GenericDashboardLayout>
    );
  }

  if (
    isError ||
    workspacesQuery.isError ||
    isSubscriptionValidityError ||
    !subscriptionValidityData
  ) {
    return (
      <GenericDashboardLayout>
        <div className="flex flex-col w-full">
          <div>{workspacesQuery.error.message}</div>
        </div>
      </GenericDashboardLayout>
    );
  }

  const hasQuota = quotaRemaining > 0;

  const handleCreateWorkspace = () => {
    navigate(`/${organization_id}/create-workspace`);
  };

  const planExpired = subscriptionValidityData?.show_banner;

  if (workspacesQuery.data.length === 0) {
    return (
      <GenericDashboardLayout>
        <div className="w-full h-full flex justify-center pb-[20%]">
          <div className="flex justify-center flex-col items-center">
            <div className="font-bold text-xl">No Workspaces</div>
            <div className="text-foreground text-sm mt-3">To get started, create a workspace</div>
            <div className="mt-7">
              {canCreateWorkspace && (
                <CreateWorkspaceButton
                  handleCreate={handleCreateWorkspace}
                  hasQuota={hasQuota}
                  isSubscriptionExpired={planExpired}
                />
              )}
            </div>
          </div>
        </div>
      </GenericDashboardLayout>
    );
  }

  return (
    <GenericDashboardLayout>
      <div className="w-full h-full flex justify-center">
        <div className="flex-1 max-w-5xl my-[5%]">
          <div className="flex justify-between">
            <div className="text-xl font-bold">Workspaces</div>
            {canCreateWorkspace && (
              <CreateWorkspaceButton
                handleCreate={handleCreateWorkspace}
                hasQuota={hasQuota}
                isSubscriptionExpired={planExpired}
              />
            )}
          </div>
          <div className="flex pt-7 w-full flex-col gap-2">
            {workspacesQuery.data?.map(
              (workspace: IWorkspace) =>
                checkWorkspacePermission(workspace) && (
                  <div key={workspace.id} className="max-w-[1500px] flex-1">
                    <WorkspaceBar workspace={workspace} />
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </GenericDashboardLayout>
  );
}

type CreateWorkspaceButtonProps = {
  hasQuota: boolean;
  isSubscriptionExpired: boolean;
  handleCreate: () => void;
};

function CreateWorkspaceButton({
  hasQuota,
  isSubscriptionExpired,
  handleCreate
}: CreateWorkspaceButtonProps) {
  if (isSubscriptionExpired) {
    console.log(isSubscriptionExpired);
    return (
      <div className="flex justify-center items-center gap-2">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="h-4 w-4">
                <FiAlertTriangle />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Plan has expired</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <Button disabled>
          <IoAdd className="h-8 w-8 pr-2" /> Create Workspaces
        </Button>
      </div>
    );
  }

  if (!hasQuota) {
    return <WorkspacePlanLimitAlert />;
  }

  return (
    <Button onClick={handleCreate}>
      <IoAdd className="h-8 w-8 pr-2" /> Create Workspace
    </Button>
  );
}
