import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import GlobalContextMenu from '@/shared/components/ContextMenu/GlobalContextMenu';
import DynamicModal from '@/shared/components/DynamicModal/DynamicModal';
import Compose from '@/shared/utils/Compose';
import GlobalAlert from '@/shared/utils/GlobalAlert';
import GlobalSpinner from '@/shared/utils/GlobalSpinner';

import App from './App';
import { ThemeProvider } from './components/theme-provider';
import { Provider as GlobalAlertProvider } from './shared/store/alert';
import { Provider as DynamicModalProvider } from './shared/store/dynamic-modal';
import { Provider as QueryEditorProvider } from './shared/store/query-editor';
import { Provider as GlobalSpinnerProvider } from './shared/store/spinner';
import { Provider as WorkspaceProvider } from './shared/store/workspace';

declare global {
  interface Window {
    api_host: string;
    status_url: string;
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider defaultTheme="light" storageKey="chista-theme">
          <Compose
            components={[
              WorkspaceProvider,
              GlobalAlertProvider,
              GlobalSpinnerProvider,
              DynamicModalProvider,
              QueryEditorProvider
            ]}
          >
            <App />
            <GlobalAlert />
            <GlobalSpinner />
            <DynamicModal />
            <GlobalContextMenu />
          </Compose>
        </ThemeProvider>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
