import React from 'react';

import { Button } from '@/shared/components/elements/Button';
import './DataTable.scss';
export interface DataTableColumn {
  title: string;
  key?: string;
  transform?: any;
  type?: string;
  className?: string;
  disabled?: boolean;
  value?: (item: any) => any;
  action?: (item: any) => void;
}

interface DataTableProps {
  columns: DataTableColumn[];
  data?: any[];
  containerStyle?: string;
}

export default function DataTable({ columns, data, containerStyle }: DataTableProps) {
  return (
    <div
      className={`bg-base-400 border-base-410 border border-solid relative w-full overflow-x-auto ${containerStyle ?? ''}`}
    >
      <table className="table data-table w-full">
        <thead className="relative">
          <tr className="text-base-650 border-b-2 border-base-410 sticky top-0">
            {columns.map((column, index) => (
              <th
                key={index}
                className={`py-5 px-3 bg-base-400 text-base-470 border border-base-410 sticky top-0 text-sm font-semibold ${column.className}`}
              >
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="overflow-y-auto">
          {data?.map((item: any, index: number) => (
            <tr key={index}>
              {columns.map((column, j) => {
                if (column.type === 'button' && column.action) {
                  return (
                    <td key={j} className={`border-l border-b border-base-410 ${column.className}`}>
                      <Button.Secondary
                        className="font-normal hover:text-neutral"
                        disabled={column.disabled}
                        onClick={() => !column.disabled && column.action?.(item)}
                      >
                        Restore
                      </Button.Secondary>
                    </td>
                  );
                }
                if (column.value) {
                  return (
                    <td key={j} className={`border-l border-b border-base-410 ${column.className}`}>
                      {column.value(item)}
                    </td>
                  );
                }

                if (column.transform) {
                  return (
                    <td key={j} className={`border-l border-b border-base-410 ${column.className}`}>
                      {column.transform(item[column.key!])}
                    </td>
                  );
                }
                return (
                  <td key={j} className={`border-l border-b border-base-410 ${column.className}`}>
                    {item[column.key!]}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
