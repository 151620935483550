import createFastContext from '@/shared/utils/CreateFastReactContext';

export interface IWorkspaceStore {
  workspaceId: string;
  workspaceName: string;
}
/**
 * This store context will be used to store the
 * current workspace information
 */
const { Provider, useStore } = createFastContext<IWorkspaceStore>({
  workspaceId: '',
  workspaceName: ''
});

export { Provider, useStore };
