export interface ICluster {
  id: number;
  name: string;
  endpoint: string;
  status: string;
  clickhouse_version: string;
  storage: string;
  created_on: string;
  updated_on: string;
  region: string;
  cluster_size: string;
  data_load_status: string;
}

export enum ClusterType {
  ClusterTypeExisting = 'existing',
  ClusterTypeNewVM = 'new_vm',
  ClusterTypeNewK8s = 'new_k8s'
}

export interface IExistingCluster {
  type: ClusterType;
  hostname: string;
  name: string;
  username: string;
  password: string;
  default_user_password: string;
}

export interface INewK8sCluster {
  type: ClusterType;
  name: string;
  size: string;
  default_user_password: string;
  create_sample_database: boolean;
  deploy_proxy: boolean;
  cold_storage?: any;
}

export type ClusterParams = {
  workspace_id: string;
  organization_id: string;
};

export type ClusterSize = {
  id: number;
  name: string;
  label: string;
  value: string;
};
