import { useMutation, useQuery } from 'react-query';

import { axios } from '@/shared/utils/axios';

import { InviteFormData } from '../types';

export async function getUserList(organization_id: string) {
  const res = await axios.get(`/organizations/${organization_id}/users`);
  return res.data;
}

type UseUserListOptions = {
  config?: object;
  organizationId: string;
};

export function useUserList({ config, organizationId }: UseUserListOptions) {
  return useQuery({
    ...config,
    queryKey: ['users', organizationId],
    queryFn: () => getUserList(organizationId)
  });
}

export default async function sendUserInvite(
  inviteFormData: InviteFormData,
  organization_id: string
) {
  const res = await axios.post(`/organizations/${organization_id}/invite_user`, inviteFormData);
  return res.data;
}

type UseSendUserInviteOptions = {
  config?: object;
  organizationId: string;
};

export function useSendUserInvite({ config, organizationId }: UseSendUserInviteOptions) {
  return useMutation({
    ...config,
    mutationFn: (inviteFormData: InviteFormData) => sendUserInvite(inviteFormData, organizationId)
  });
}
