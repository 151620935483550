import React from 'react';

import { Success, Error } from '@/shared/components/elements/Alerts/Alerts';
import { IAlertStore, useAlertHooks, useStore as useAlertStore } from '@/shared/store/alert';

const GlobalAlert = () => {
  const [store] = useAlertStore<IAlertStore>();
  const { hideAlert } = useAlertHooks();
  const { text, type } = store;

  if (text && type) {
    if (type === 'success') {
      return <Success hide={hideAlert} message={text} />;
    } else {
      return <Error hide={hideAlert} message={text} />;
    }
  } else {
    return <></>;
  }
};

export default GlobalAlert;
