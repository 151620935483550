import React from 'react';

export default function NotFound() {
  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <div className="flex">
        <h1 className="text-6xl">404</h1>
        <div className="divider divider-horizontal"></div>
        <div>
          <h1 className="text-6xl">Page not found</h1>
          <h2>Please check the url in address bar and try again.</h2>
          <div></div>
        </div>
      </div>
    </div>
  );
}
