import { NavLink } from 'react-router-dom';

import { TopNav, TopNavItem } from '@/components/topnav';

type ClusterNavProps = {
  navItems: any;
};

export default function SettingsNav({ navItems }: ClusterNavProps) {
  return (
    <div className="h-full w-full text-sm">
      <TopNav>
        {navItems.map((v: any) => (
          <NavLink className="h-full" to={v.path} key={v.label}>
            {({ isActive }) => {
              return (
                <TopNavItem isActive={isActive}>
                  <div>{v.icon}</div>
                  <div>{v.label}</div>
                </TopNavItem>
              );
            }}
          </NavLink>
        ))}
      </TopNav>
    </div>
  );
}
