import { AxiosError } from 'axios';
import React from 'react';
import { BsArrowRight, BsCheck2, BsDash } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';

import { usePlansList } from '@/api/tiers/tierApis';
import {
  useOrganizationAddressQuery,
  useOrganizationDetails,
  useOrganizationSubscriptionLinkMutation,
  useOrganizationSubscriptionValidity
} from '@/pages/Organization/api/OrganizationApi';
import Spinner from '@/shared/components/elements/Spinner/Spinner';
import { useAlertHooks } from '@/shared/store/alert';

import { Feature } from '../../types';

import { getFeaturesList } from './utils';

export default function Plans() {
  const { organization_id } = useParams();
  const navigate = useNavigate();
  const { setAlert } = useAlertHooks();
  const {
    isError: isOrgError,
    isLoading: isOrgLoading,
    data: orgData
  } = useOrganizationDetails({ organizationId: organization_id! });
  const {
    isError: isPlansListError,
    isLoading: isPlansListLoading,
    data: plansListData
  } = usePlansList();

  const {
    isError: isOrgAddressError,
    error: orgAddressError,
    isLoading: isOrgAddressLoading,
    data: orgAddressData
  } = useOrganizationAddressQuery(organization_id!, 'billing');

  const {
    isError: isSubscriptionValidityError,
    isLoading: isSubscriptionValidityLoading,
    data: subscriptionValidityData
  } = useOrganizationSubscriptionValidity(organization_id!);

  const createSubscriptionLinkMutation = useOrganizationSubscriptionLinkMutation(
    organization_id!,
    (data: any) => {
      if (data.url) {
        window.location.replace(data.url);
      }
    },
    (err: AxiosError<{ message: string }>) => {
      const errorMessage = err.response?.data.message;
      if (errorMessage !== undefined) {
        setAlert(errorMessage, 'error');
      }
    }
  );

  if (isPlansListLoading || isOrgLoading || isOrgAddressLoading || isSubscriptionValidityLoading) {
    return <Spinner />;
  }

  if (
    isPlansListError ||
    isOrgError ||
    !plansListData ||
    isSubscriptionValidityError ||
    !subscriptionValidityData ||
    (isOrgAddressError && (orgAddressError as any).response.status !== 404)
  ) {
    return <h1>Error</h1>;
  }

  const { features, featuresTierMap } = getFeaturesList(plansListData);

  const plansList = plansListData?.map((plan: any) => {
    plan.tier.isActive = plan.tier.id === orgData?.tier.id;
    return plan;
  });

  for (const plan of plansList) {
    if (!plan.tier.isActive) {
      plan.tier.isUpgradable = false;
    } else {
      break;
    }
  }

  const upgradePlan = (tier: any) => {
    const isRenewable = subscriptionValidityData?.show_banner;
    if (tier.isActive && !isRenewable) return;

    if (!tier.isActive && tier.isUpgradable === false) return;

    // Check if billing address is updated
    if (!orgAddressData) {
      navigate(`/${organization_id}/settings/update-address`);
    }

    // if billing address is updated, naviate to payment gateway
    createSubscriptionLinkMutation.mutate(tier.name);
  };

  return (
    <div>
      <div className="text-base font-bold">Plans</div>
      <div className="text-xs font-medium text-chista-grey">
        Options for every user, organization, and use case
      </div>

      <div className="mt-10 mb-10">
        <div className="grid grid-cols-5">
          {/* offeset */}
          <div className="col-span-1"></div>
          {plansList?.map((plan: any, index: number) => (
            <div key={index} className="mb-5 flex justify-center">
              <div>
                <div className="text-primary-100 font-bold text-xl">{plan.tier.label}</div>
                <div className="font-bold text-3xl mt-5 mb-2">
                  {plan.tier.name === 'critical_business' ? (
                    <span className="text-base text-base-650 font-normal">Needs Assessment</span>
                  ) : (
                    <span>${plan.tier.amount}</span>
                  )}
                </div>
                {plan.tier.name === 'critical_business' ? null : (
                  <div className="text-xs text-chista-grey">Per month</div>
                )}
              </div>
            </div>
          ))}

          {features.map((feature: Feature, i: number) => (
            <React.Fragment key={i}>
              <>
                <div className="col-span-1 py-5 px-4">{feature.name}</div>
                {plansList?.map((plan: any, j: number) => (
                  <div key={j} className="flex items-center justify-center">
                    {featuresTierMap[feature.name][plan.tier.name] ? (
                      <BsCheck2 className="w-6 h-6" />
                    ) : (
                      <BsDash className="w-6 h-6" />
                    )}
                  </div>
                ))}
                <div className="col-span-5">
                  <hr className="border-none h-[1px] bg-base-700" />
                </div>
              </>
            </React.Fragment>
          ))}

          <div className="col-span-1 "></div>
          {plansList?.map((plan: any, i: number) => {
            if (plan.tier.name !== 'startup') {
              return (
                <div key={i} className="flex items-center text-base-650 py-5 justify-center">
                  Get Started&nbsp;&nbsp; <BsArrowRight />
                </div>
              );
            }

            const canUpgrade = !plan.tier.isActive && plan.tier.isUpgradable !== false;
            const isRenewable = subscriptionValidityData?.show_banner;
            return (
              <React.Fragment key={i}>
                {plan.tier.isActive ? (
                  <div
                    className={`flex items-center ${
                      isRenewable ? 'text-primary cursor-pointer' : 'text-chista-grey'
                    } py-5 justify-center`}
                    onClick={() => upgradePlan(plan.tier)}
                  >
                    {`${isRenewable ? 'Renew Plan' : 'Get Started'}`}&nbsp;&nbsp; <BsArrowRight />
                  </div>
                ) : (
                  <div
                    className={`flex items-center ${
                      canUpgrade ? 'text-primary cursor-pointer' : 'text-chista-grey'
                    } py-5 justify-center`}
                    onClick={() => upgradePlan(plan.tier)}
                  >
                    Get Started&nbsp;&nbsp; <BsArrowRight />
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}
