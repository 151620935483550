export enum backupStatus {
  success = 'success',
  failed = 'failed',
  inprogress = 'inprogress'
}

export interface IBackup {
  id: number;
  database_name: string;
  backup_disk: string;
  backup_file_name: string;
  backup_schedule_id: number;
  cluster_id: number;
  end_time: string;
  start_time: string;
  output: string;
  status: backupStatus;
  restore_id: number;
}

export interface IBackupSchedule {
  id: number;
  database_name: string;
  schedule: string;
  created_on: string;
  created_by: string;
  last_successful_run: string;
  last_run: string;
  last_run_status: backupStatus;
  last_run_output: string;
}
