import { AiOutlineUser } from 'react-icons/ai';
import { CgNotes } from 'react-icons/cg';
import { HiOutlineDatabase } from 'react-icons/hi';
import { HiOutlineDocumentMagnifyingGlass } from 'react-icons/hi2';
import { MdSchedule } from 'react-icons/md';
import { RiBarChart2Line } from 'react-icons/ri';
import { TbDatabaseImport, TbSettings } from 'react-icons/tb';
import { Outlet, useParams } from 'react-router-dom';

import GenericDashboardLayout from '@/layouts/GenericDashboardLayout';
import Spinner from '@/shared/components/elements/Spinner/Spinner';

import useClusterPermissions from '../hooks/useClusterPermissions';

import ClusterNav from './ClusterNav';

export default function Cluster() {
  const { workspace_id, organization_id, cluster_id } = useParams<{
    workspace_id: string;
    organization_id: string;
    cluster_id: string;
  }>();

  const {
    isLoading,
    isError,
    canQuery,
    canMetrics,
    canBackup,
    canDelete,
    canManageUsers,
    canOltpArchive
  } = useClusterPermissions({
    orgnizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!
  });

  const navItems = generateTabItems(
    canQuery,
    canMetrics,
    canBackup,
    canDelete,
    canManageUsers,
    canOltpArchive
  );

  if (isLoading) {
    return (
      <GenericDashboardLayout>
        <div className="flex flex-col w-full">
          <Spinner />
        </div>
      </GenericDashboardLayout>
    );
  }

  if (isError) {
    return (
      <GenericDashboardLayout>
        <div className="flex flex-col w-full">
          <div>Error</div>
        </div>
      </GenericDashboardLayout>
    );
  }

  const filterTabItemsBasedOnClusterStatus = () => {};
  filterTabItemsBasedOnClusterStatus();

  return (
    <GenericDashboardLayout>
      <div className="grid w-full h-full gap-1 grid-cols-16 grid-rows-12">
        <div className="flex items-center col-start-1 row-span-1 col-end-17">
          <ClusterNav navItems={navItems} />
        </div>
        <div className="col-start-2 row-start-2 overflow-auto row-span-14 col-end-16">
          <Outlet />
        </div>
      </div>
    </GenericDashboardLayout>
  );
}

function generateTabItems(
  canQuery: boolean,
  canMetrics: boolean,
  canBackup: boolean,
  canDelete: boolean,
  canManageUsers: boolean,
  canOltpArchive: boolean
) {
  const items = [];
  items.push({
    label: 'Overview',
    icon: <HiOutlineDocumentMagnifyingGlass className="mr-2" size={'24px'} />,
    path: 'overview'
  });

  if (canQuery) {
    items.push({
      label: 'Query Editor',
      icon: <HiOutlineDatabase className="mr-2" size={'24px'} />,
      path: 'query-editor'
    });
  }

  if (canMetrics) {
    items.push({
      // title: (
      //   <span className="flex">
      //     Metrics{' '}
      //     <span className="flex items-center px-1 mx-1 text-xs border-0 rounded-md text-info bg-accent">
      //       Beta
      //     </span>
      //   </span>
      // ),
      label: 'Metrics',
      icon: <RiBarChart2Line className="mr-2" size={'24px'} />,
      path: 'metrics'
    });
  }

  if (canBackup) {
    items.push(
      {
        label: 'Backups',
        icon: <TbDatabaseImport className="mr-2" size={'24px'} />,
        path: 'backups'
      },
      {
        label: 'Backup Schedules',
        icon: <MdSchedule className="mr-2" size={'24px'} />,
        path: 'backup-schedules'
      }
    );
  }
  if (canManageUsers) {
    items.push({
      label: 'Cluster Users',
      icon: <AiOutlineUser className="mr-2" size={'24px'} />,
      path: 'user-management'
    });
  }
  if (canOltpArchive) {
    // items.push({
    //   label: 'OLTP',
    //   icon: <RiDatabaseLine className="mr-2" size={'24px'} />,
    //   path: 'oltp-archive/list'
    // });
  }
  items.push({
    label: 'Logs',
    icon: <CgNotes className="mr-2" size={'24px'} />,
    path: 'logs'
  });
  if (canDelete) {
    items.push({
      label: 'Settings',
      icon: <TbSettings className="mr-2" size={'24px'} />,
      path: 'settings'
    });
  }

  return items;
}
