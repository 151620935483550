import React from 'react';
import { VscCircleLargeFilled } from 'react-icons/vsc';

type Props = {
  status: string;
};

export default function ClusterStatus({ status }: Props) {
  return (
    <div className="flex h-8 items-center">
      {status === 'success' && <ClusterStatusIcon text="Running" color="text-success" />}
      {status === 'failed' && <ClusterStatusIcon text="Failed" color="text-destructive" />}
      {status === 'provisioning' && <ClusterStatusIcon text="Provisioning" color="text-warning" />}
      {status === 'suspended' && <ClusterStatusIcon text="Suspended" color="text-warning-500" />}
      {status === 'sleeping' && <ClusterStatusIcon text="Sleeping" color="text-gray-400" />}
      {status === 'starting' && <ClusterStatusIcon text="Starting" color="text-warning" />}
    </div>
  );
}

type ClusterStatusIconProps = {
  text: string;
  color: string;
};

function ClusterStatusIcon({ text, color }: ClusterStatusIconProps) {
  return (
    <div
      className={`flex justify-center items-center border-0 px-0 font-bold p-3 text-sm ${color}`}
    >
      <VscCircleLargeFilled className={`w-2 h-2 mr-2 ${color}`} />
      {text}
    </div>
  );
}
