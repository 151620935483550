import React from 'react';

type MetadataControlPanelProps = {
  clusterName: string;
  credentialId: string;
};

export default function MetadataControlPanel({ clusterName }: MetadataControlPanelProps) {
  return (
    <div className="w-full h-28">
      <div className="text-sm py-2 font-bold">Cluster</div>
      <div className="w-full flex justify-between items-center border px-3 py-4 bg-base-475 border-base-475">
        <div>{clusterName}</div>
      </div>
    </div>
  );
}
