import React from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { useDeleteCluster } from '@/pages/ClusterList/api/ClusterApi';
import { ICluster } from '@/pages/ClusterList/types';

type DeleteCardProps = {
  cluster: ICluster;
};

export default function ClusterDeleteDialog({ cluster }: DeleteCardProps) {
  const queryClient = useQueryClient();
  const { organization_id, workspace_id } = useParams();
  const navigate = useNavigate();
  const deleteClusterMutation = useDeleteCluster({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    config: {
      onSuccess: () => {
        queryClient.invalidateQueries(['clusters', workspace_id]);
        queryClient.invalidateQueries('userDetails');
        navigate(`/${organization_id}/workspace/${workspace_id}`, { replace: true });
      }
    }
  });

  function handleDelete() {
    deleteClusterMutation.mutate(cluster.id);
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="destructive">Delete Cluster</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Cluster</AlertDialogTitle>
          <AlertDialogDescription className="text-left">
            This will delete Database, Data and Backups.This action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            asChild
            className="bg-destructive hover:bg-destructive/90"
            onClick={handleDelete}
          >
            <Button variant="destructive">Delete</Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
