import { useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Spinner } from '@/components/ui/spinner';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useConnectionDetails } from '@/pages/ClusterList/api/ConnectionApi';

import CopyToClipboardButton from './CopyToClipboardButton';

export default function ConnectionDialog() {
  const { organization_id, workspace_id, cluster_id } = useParams();
  const connectionDetailsQuery = useConnectionDetails({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!
  });
  if (connectionDetailsQuery.isLoading) {
    return <Spinner />;
  }

  if (connectionDetailsQuery.isError) {
    return <div>Error fetching connection details</div>;
  }
  const { username, password, curl_string, client_string } = connectionDetailsQuery.data;
  console.log(connectionDetailsQuery.data);
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>Connect</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-lg">
        <DialogHeader>
          <DialogTitle>Connection Details</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center w-full gap-2">
          <div className="flex gap-2 justify-start items-center w-full">
            <Label className="px-1">Username:</Label>
            <Input readOnly value={username} />
            <CopyToClipboardButton content={username} />
          </div>
          <div className="flex gap-2 justify-start items-center w-full">
            <Label className="px-1">Password:</Label>
            <Input readOnly value={password} />
            <CopyToClipboardButton content={password} />
          </div>
          <div className="pt-4 w-full">
            <Tabs defaultValue="native" className="w-full">
              <TabsList>
                <TabsTrigger value="native">Native</TabsTrigger>
                <TabsTrigger value="https">HTTPS</TabsTrigger>
              </TabsList>
              <hr className="border-none h-[1px] bg-foreground/10" />
              <TabsContent value="native">
                <div className="flex p-2 justify-between items-start bg-card rounded">
                  <div className="whitespace-pre h-24 text-xs">{client_string}</div>
                  <CopyToClipboardButton containerClass="h-0" content={client_string} />
                </div>
              </TabsContent>
              <TabsContent value="https">
                <div className="flex p-2 justify-between items-start bg-card rounded">
                  <div className="whitespace-pre h-24 text-xs">{curl_string}</div>
                  <CopyToClipboardButton containerClass="h-0" content={curl_string} />
                </div>
              </TabsContent>
            </Tabs>
          </div>
        </div>
        <DialogFooter className="justify-end sm:justify-start">
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
