import cronstrue from 'cronstrue';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { useToast } from '@/components/ui/use-toast';

import { useCreateBackup } from '../api/MetadataApi';
import { BackupData } from '../types';
type Schedule = {
  id: number;
  value: string;
  label: string;
};
const schedules: Schedule[] = [
  { id: 0, value: ' ', label: 'Now' },
  { id: 1, value: '0 0 * * *', label: 'Daily (at 12 AM)' },
  { id: 2, value: '0 0 * * 0', label: 'Weekly (on Sunday at 12 AM' },
  { id: 3, value: '0 0 1 * *', label: 'Monthly (on 1st at 12 AM)' }
];

type CreateBackupFormProps = {
  credentialId: string;
};

export default function CreateBackupForm({ credentialId }: CreateBackupFormProps) {
  const { organization_id, workspace_id, cluster_id } = useParams();
  const [params] = useSearchParams();
  const [startFrom, setStartFrom] = useState(new Date());
  const [cron, setCron] = useState('');
  const [cronDescription, setCronDescription] = useState('');
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const createBackupMutation = useCreateBackup({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!,
    config: {
      onSuccess: () => {
        toast({
          title: 'Backup created'
        });
        queryClient.invalidateQueries('backups');
        navigate(
          `/${organization_id}/workspace/${workspace_id}/cluster/${cluster_id}/backup-schedules`
        );
      },
      onError: () => {
        toast({
          title: 'Backup Failed',
          variant: 'destructive'
        });
      }
    }
  });

  const form = useForm({ mode: 'all' });

  function onSubmit(formData: any) {
    const db_name = params.get('db');
    const data: BackupData = {
      db_name: db_name!,
      backup_path: 'backups',
      credential_id: credentialId,
      schedule: formData.schedule,
      start_from: startFrom
    };
    if (formData.schedule === ' ') {
      data.schedule = '';
    }
    if (formData.schedule === 'custom') {
      if (formData.repeat) {
        data.schedule = cron;
      } else {
        data.schedule = '';
      }
    }
    createBackupMutation.mutate(data);
  }

  function changeStartFrom(event: any) {
    if (!event.target.validity.valid) return;
    const dt = new Date(Date.parse(`${event.target.value}:00Z`));
    setStartFrom(dt);
  }

  function handleCronChange(e: any) {
    setCron(e.target.value);
    try {
      setCronDescription(cronstrue.toString(e.target.value));
    } catch (error) {
      setCronDescription('Invalid syntax');
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <FormField
          control={form.control}
          name="schedule"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Quick Schedules</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a schedule" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <>
                    {schedules.map((v) => (
                      <SelectItem key={v.id} value={v.value}>
                        {v.label}
                      </SelectItem>
                    ))}
                  </>
                  <SelectItem value="custom">Custom</SelectItem>
                </SelectContent>
              </Select>
              <FormDescription>Select a schedule</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        {form.watch('schedule') === 'custom' && (
          <>
            <FormField
              control={form.control}
              name="startTime"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Run From</FormLabel>
                  <Input
                    {...field}
                    type="datetime-local"
                    value={startFrom.toISOString().slice(0, 16)}
                    onChange={changeStartFrom}
                  />
                  <FormDescription>Select starting time</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="repeat"
              render={({ field }) => (
                <FormItem className="flex items-end gap-2">
                  <FormLabel className="">Repeat</FormLabel>
                  <FormControl>
                    <Switch checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                </FormItem>
              )}
            />
          </>
        )}
        {form.watch('schedule') === 'custom' && form.watch('repeat') && (
          <FormField
            control={form.control}
            name="cron"
            render={({ field }) => (
              <FormItem>
                <FormLabel>CRON (UTC)</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Schedule in cron syntax (eg. '0 0 * * *' = At 00:00 everyday)"
                    {...field}
                    value={cron}
                    onChange={handleCronChange}
                  />
                </FormControl>

                <FormDescription
                  className={`${cronDescription === 'Invalid syntax' ? 'text-destructive' : ''}`}
                >
                  {cronDescription}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <div className="w-full flex justify-end gap-2">
          {form.watch('schedule') === 'custom' &&
          form.watch('repeat') &&
          cronDescription === 'Invalid syntax' ? (
            <Button disabled>Create Backup</Button>
          ) : (
            <Button type="submit">Create Backup</Button>
          )}
        </div>
      </form>
    </Form>
  );
}
