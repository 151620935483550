import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';

import { useDeleteWorkspace } from '../api/WorkspaceApi';
import { IWorkspace } from '../types';

type DeleteWorkspaceProps = {
  organizationId: string;
  workspace: IWorkspace;
};
export default function DeleteWorkspace({ organizationId, workspace }: DeleteWorkspaceProps) {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [input, setInput] = useState('');
  const deleteWorkspaceMutation = useDeleteWorkspace({
    organizationId,
    config: {
      onSuccess: () => {
        toast({
          title: 'Success',
          description: 'Workspace Deleted'
        });
        queryClient.invalidateQueries(['workspaces']);
      }
    }
  });
  function handleDelete() {
    deleteWorkspaceMutation.mutate(workspace.id);
  }
  function handleChange(e: any) {
    setInput(e.target.value);
  }

  const isDisabled = !(workspace.name === input);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>Delete</DropdownMenuItem>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Delete Workspace?</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete the workspace? Deleting a workspace will delete all the
            clusters in it. This action cannot be undone.
          </DialogDescription>
        </DialogHeader>
        <div className="py-3">
          Please enter <span className="font-semibold">{workspace.name}</span> to confirm.
        </div>
        <Input type="text" placeholder="cluster-xyz" onChange={handleChange} />
        <DialogFooter className="sm:justify-start">
          <DialogClose>
            <Button
              variant={'destructive'}
              type="submit"
              onClick={handleDelete}
              disabled={isDisabled}
            >
              Delete
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
