import React from 'react';

import { cn } from '@/lib/utils';

const TopNav = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('flex items-center border-b-[1px] w-full h-12 bg-card', className)}
      {...props}
    >
      {children}
    </div>
  )
);

TopNav.displayName;

interface TopNavItemProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  isActive: boolean;
}

const TopNavItem = React.forwardRef<HTMLDivElement, TopNavItemProps>(
  ({ className, children, isActive, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        `flex items-center h-full gap-1 px-2 pt-1 hover:bg-muted ${isActive && 'text-primary border-b-2 border-primary'}`,
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
);

TopNavItem.displayName;

export { TopNav, TopNavItem };
