import React from 'react';
import { useParams } from 'react-router-dom';

import { useCluster } from '@/pages/ClusterList/api/ClusterApi';
import Spinner from '@/shared/components/elements/Spinner/Spinner';

import ClusterDeleteCard from './components/ClusterDeleteCard';
import ClusterSleepCard from './components/ClusterSleepCard';

export default function ClusterSettings() {
  const { organization_id, workspace_id, cluster_id } = useParams();
  const { data, isError, isLoading } = useCluster({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!
  });

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>Unable to fetch cluster details</div>;
  }

  return (
    <div className="flex flex-col">
      <h1 className="font-bold">General</h1>
      <div className="flex items-center pt-8 pb-12">
        <div className="font-bold">Cluster Name</div>
        <div className="border-2 p-2 ml-28 w-80 rounded border-base-475">{data.name}</div>
      </div>
      <div className="h-[1px] border flex-1 mb-10 mt-14 border-base-475"></div>
      <ClusterSleepCard cluster={data} />
      <ClusterDeleteCard cluster={data} />
    </div>
  );
}
