import { useEffect } from 'react';

const LOGIN_PATH = '/login';

function Login() {
  useEffect(() => {
    window.location.href = '/api/login';
  }, []);

  return null;
}

export { Login, LOGIN_PATH };
