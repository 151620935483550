import { useToast } from '@/components/ui/use-toast';
import { API_URL } from '@/shared/config';
import { axios } from '@/shared/utils';
import { RedirectURLResponse } from '@/types';

export default function ResetPassword() {
  const { toast } = useToast();
  async function resetPassword() {
    const response = await axios.put(`${API_URL}/account/password`);
    if (response.status === 200) {
      const redirectResponse = response.data as RedirectURLResponse;
      window.location.href = redirectResponse.redirect_url;
    } else {
      toast({
        title: 'Error',
        description: 'Error resetting password',
        variant: 'destructive'
      });
    }
  }
  return (
    <div className="w-full" onClick={resetPassword}>
      Reset Password
    </div>
  );
}
