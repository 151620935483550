import React from 'react';
import { BsArrowLeftSquare } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { BreadCrump, BreadCrumpItem } from '@/shared/components/BreadCrump/BreadCrump';

interface TitleProps {
  title: string;
  prevRedirectionLink?: string;
  breadCrumpConfig?: BreadCrumpItem[];
  rightComponent?: any;
}
function PageTitle({ title, breadCrumpConfig, rightComponent, prevRedirectionLink }: TitleProps) {
  return (
    <div className="flex">
      <div className="flex-1 flex flex-col">
        <div className="flex items-center h-full">
          {prevRedirectionLink ? (
            <Link to={prevRedirectionLink}>
              <div className="mr-2 ">
                <BsArrowLeftSquare className="w-6 h-6 text-primary-600 hover:text-primary-100" />
              </div>
            </Link>
          ) : null}

          <div className="font-bold text-base-900 text-xl"> {title}</div>
        </div>

        {breadCrumpConfig ? <BreadCrump config={breadCrumpConfig}></BreadCrump> : null}
      </div>
      <div className="flex-1">{rightComponent || null}</div>
    </div>
  );
}

export default PageTitle;
