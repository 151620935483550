import { createColumnHelper } from '@tanstack/react-table';

import DataTable from '@/components/ui/datatable';
import { formatDuration, formatTime } from '@/shared/utils';

import { StatusBadge } from './BackupStatusBadget';
import RestoreBackup from './RestoreBackup';

const columnHelper = createColumnHelper<any>();

const columns = [
  columnHelper.accessor('database_name', {
    header: () => 'DB NAME'
  }),
  columnHelper.accessor('backup_file_name', {
    header: () => 'BACKUP FILENAME'
  }),
  columnHelper.accessor('status', {
    header: () => 'STATUS',
    cell: (cell) => <StatusBadge badgeStatus={cell.getValue()} />
  }),
  columnHelper.accessor('start_time', {
    header: () => 'START TIME',
    cell: (cell) => formatTime(cell.getValue())
  }),
  columnHelper.accessor('end_time', {
    header: () => 'END TIME',
    cell: (cell) => formatTime(cell.getValue())
  }),
  columnHelper.display({
    id: 'latency',
    header: () => 'LATENCY',
    cell: ({ row }) => formatDuration(row.original.start_time, row.original.end_time)
  }),
  columnHelper.display({
    header: () => 'Actions',
    id: 'actions',
    cell: ({ row }) => {
      return <RestoreBackup backupId={row.original.id} />;
    }
  })
];

type BackupsTableProps = {
  data: any;
};

export default function BackupsTable({ data }: BackupsTableProps) {
  return <DataTable columns={columns} data={data} />;
}
