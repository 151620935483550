export function getFeaturesList(plansList: any) {
  const features: any = [];
  const featuresTierMap: any = {};

  for (const plan of plansList) {
    for (const feature of plan.features) {
      if (!featuresTierMap[feature.name]) {
        features.push(feature);
        featuresTierMap[feature.name] = {};
      }

      featuresTierMap[feature.name][plan.tier.name] = true;
    }
  }

  return {
    features,
    featuresTierMap
  };
}
