import { CiClock2 } from 'react-icons/ci';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';

type ExpiryBannerProps = {
  isExpired: boolean;
  daysRemaining?: number;
};

export default function ExpiryBanner({ isExpired, daysRemaining }: ExpiryBannerProps) {
  const { organization_id } = useParams();
  const navigate = useNavigate();
  const expiryText =
    'To continue enjoying uninterrupted access to our services and features, please take action to manage your subscription immediately.';
  function handleClick() {
    navigate(`/${organization_id}/settings/billing`);
  }
  return (
    <div className="col-span-full row-span-1">
      <div
        className={`h-full flex justify-center items-center gap-4 ${isExpired ? 'bg-destructive text-destructive-foreground' : 'bg-warning text-warning-foreground'}`}
      >
        <CiClock2 />
        {!isExpired && <div>{daysRemaining} days left</div>}
        <div className="flex justify-center items-center gap-2 text-sm">{expiryText}</div>
        <Button variant={'secondary'} onClick={handleClick}>
          Upgrade
        </Button>
      </div>
    </div>
  );
}
