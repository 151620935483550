import React from 'react';
import { useParams } from 'react-router-dom';

import Spinner from '@/shared/components/elements/Spinner/Spinner';

import { useBackupSchedules } from '../api/BackupSchedulesApi';
import { USER_BACKUPS_TABLE_CONFIG } from '../constants';
import { useBackupPermissions } from '../utils';

import { BackupScheduleActions } from './BackupScheduleActions';
import UserBackupsTable from './UserBackupsTable';

interface UserBackupsTableProps {
  user: any;
}

export default function UserBackups({ user }: UserBackupsTableProps) {
  const { workspace_id, cluster_id, organization_id } = useParams();
  const { canDelete } = useBackupPermissions(user, workspace_id!, cluster_id!);

  const backupSchedulesQuery = useBackupSchedules({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!,
    backup_type: 'user'
  });

  const columns: DataTableColumn[] = [...USER_BACKUPS_TABLE_CONFIG];
  columns.push({
    title: 'ACTIONS',
    value: (dataItem: any) => {
      return (
        <BackupScheduleActions
          canDelete={canDelete}
          backupId={dataItem.id}
          log={dataItem.last_run_output}
        />
      );
    }
  });

  if (backupSchedulesQuery.isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center bg-base-200">
        <Spinner />
      </div>
    );
  }

  if (backupSchedulesQuery.isError) {
    return <div className="flex flex-1 items-center justify-center bg-base-200">Error</div>;
  }

  if (!backupSchedulesQuery.data) {
    return (
      <div className="flex flex-1 flex-grow items-center justify-center bg-base-200">
        <div>No backups created</div>
      </div>
    );
  }

  return <UserBackupsTable data={backupSchedulesQuery.data} />;
}
