import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useUserDetails } from '@/shared/api/InitApis';
import ButtonGroup from '@/shared/components/ButtonGroup/ButtonGroup';

import { DefaultBackups } from './components/DefaultBackups';
import UserBackups from './components/UserBackups';

export default function BackupSchedules() {
  const { organization_id } = useParams();

  const [selectedBackups, setSelectedBackups] = useState('User Backups');

  const user = useUserDetails({ organizationId: organization_id! });
  const backupScheduleGroups = ['User Backups', 'Default Backups'];

  return (
    <div className="flex flex-col flex-1 bg-base-200 overflow-x-auto">
      <div className="flex justify-between px-2 pt-4 pb-9">
        <h1 className="font-bold">Backup Schedules</h1>
        <ButtonGroup items={backupScheduleGroups} onChange={setSelectedBackups} />
      </div>
      {selectedBackups === 'User Backups' ? (
        <UserBackups user={user} />
      ) : (
        <DefaultBackups user={user} />
      )}
    </div>
  );
}
