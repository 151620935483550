import React from 'react';

import Header from '@/shared/components/Header/Header';

type Props = {
  children: React.ReactNode;
};

export default function OrganizationLayout({ children }: Props) {
  return (
    <div className="grid grid-rows-16 grid-cols-16 h-screen">
      <div className="row-span-1 col-span-full">
        <Header showNav={false} />
      </div>
      <main className="row-span-15 col-span-full bg-base-200">{children}</main>
    </div>
  );
}
