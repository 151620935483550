import { useMutation, useQuery } from 'react-query';

import { axios } from '@/shared/utils/axios';

import { BackupData } from '../types';

export async function createBackup(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  backupData: BackupData
) {
  const res = await axios.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/backup-schedules`,
    backupData
  );
  return res.data;
}

type UseCreateBackupOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useCreateBackup({
  config,
  organizationId,
  workspaceId,
  clusterId
}: UseCreateBackupOptions) {
  return useMutation({
    ...config,
    mutationFn: (formData: BackupData) =>
      createBackup(organizationId, workspaceId, clusterId, formData)
  });
}

interface IGetDatabaseTablesRequest extends IGetSchemasRequest {
  schema: string;
}

export async function getDatabaseTables({
  organizationId,
  workspaceId,
  clusterId,
  schema,
  credentialId
}: IGetDatabaseTablesRequest) {
  const res = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/metadata/schemas/${schema}/tables?credential_id=${credentialId}`
  );
  return res.data;
}

type UseDatabaseTablesOptions = IGetDatabaseTablesRequest & {
  config?: object;
};

export function useDatabaseTables({
  config,
  organizationId,
  workspaceId,
  clusterId,
  schema,
  credentialId
}: UseDatabaseTablesOptions) {
  return useQuery({
    ...config,
    queryKey: ['table', schema],
    queryFn: () =>
      getDatabaseTables({ organizationId, workspaceId, clusterId, schema, credentialId })
  });
}

interface IGetSchemasRequest {
  organizationId: string;
  workspaceId: string;
  clusterId: string;
  credentialId: string;
}

async function getSchemas({
  organizationId,
  workspaceId,
  clusterId,
  credentialId
}: IGetSchemasRequest) {
  const res = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/metadata/schemas?credential_id=${credentialId}`
  );
  return res.data;
}

type UseSchemasOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
  credentialId: string;
};

export function useSchemas({
  config,
  organizationId,
  workspaceId,
  clusterId,
  credentialId
}: UseSchemasOptions) {
  return useQuery({
    ...config,
    queryKey: ['schema', clusterId],
    queryFn: () => getSchemas({ organizationId, workspaceId, clusterId, credentialId })
  });
}
