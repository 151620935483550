import { useNavigate } from 'react-router-dom';

import { API_URL } from '@/shared/config';

export default function Logout() {
  const navigate = useNavigate();
  function deleteCookie(name: string) {
    document.cookie =
      name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=.platform.local';
  }
  async function logout() {
    await fetch(`${API_URL}/logout`, {
      method: 'get',
      credentials: 'include'
    });
    deleteCookie('access_token');
    deleteCookie('refresh_token');
    deleteCookie('id_token');
    navigate('/login', { replace: true });
    localStorage.clear();
    window.location.reload();
  }
  return (
    <div className="w-full" onClick={logout}>
      Logout
    </div>
  );
}
