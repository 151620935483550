import cronstrue from 'cronstrue';
import React from 'react';
import { BsPencilFill } from 'react-icons/bs';

import { GetPermissionNode } from '@/shared/utils/authorization/authUtils';

type FormatScheduleProps = {
  schedule: string;
  edit?: () => void;
};

export function FormatSchedule({ schedule, edit }: FormatScheduleProps) {
  if (schedule === '') {
    return <div className="badge badge-outline gap-2 rounded-full">once</div>;
  }
  const title = cronstrue.toString(schedule);
  return (
    <>
      <div className="tooltip tooltip-top" data-tip={title}>
        <div className="badge badge-outline gap-2 rounded-full">{schedule}</div>
      </div>
      {edit && (
        <div className="tooltip ml-2" data-tip="Edit">
          <button onClick={edit}>
            <BsPencilFill className="text-primary-600 h-4 w-4" />
          </button>
        </div>
      )}
    </>
  );
}

export function useBackupPermissions(user: any, workspace_id: string, cluster_id: string) {
  let canDelete = false;
  if (user.data) {
    const permRoot = new Map(Object.entries(user.data.permissions));
    const clusterPerms = GetPermissionNode(permRoot, [
      'workspaces',
      `id-${workspace_id}`,
      'clusters',
      `id-${cluster_id}`,
      'backups'
    ]);
    canDelete = clusterPerms?.has('delete');
  }

  let canBackup = false;
  if (user.data) {
    const permRoot = new Map(Object.entries(user.data.permissions));
    const clusterPerms = GetPermissionNode(permRoot, [
      'workspaces',
      `id-${workspace_id}`,
      'clusters',
      `id-${cluster_id}`
    ]);
    canBackup = clusterPerms?.has('backups');
  }

  return { canBackup, canDelete };
}
