import createFastContext from '@/shared/utils/CreateFastReactContext';

const ALERT_TIME = 5000;
export interface IAlertStore {
  text: string;
  type: string;
}
/**
 * This store context will be used to store the
 * global alert type store
 */
const { Provider, useStore } = createFastContext<IAlertStore>({
  text: '',
  type: ''
});

export function useAlertHooks() {
  const [, setAlertStore] = useStore();

  const hideAlert = () => {
    setAlertStore({ text: '', type: '' });
  };

  const setAlert = (text: string, type: string) => {
    setAlertStore({ text, type });

    setTimeout(() => {
      hideAlert();
    }, ALERT_TIME);
  };

  return { hideAlert, setAlert };
}

export { Provider, useStore };
