import { IoAdd } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';

export default function ClusterPlanLimitAlert() {
  const { organization_id } = useParams();
  const navigate = useNavigate();
  function handlePlanUpgrade() {
    navigate(`/${organization_id}/settings/billing`);
  }
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button>
          <IoAdd className="h-8 w-8 pr-2" /> Create Cluster
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Maximum number of clusters reached.</AlertDialogTitle>
          <AlertDialogDescription>
            Upgrade plan to create more clusters or contact ChistaData support team.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handlePlanUpgrade}>Upgrade now</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
