import { useMutation, useQuery } from 'react-query';

import { axios } from '@/shared/utils/axios';

async function getLogs(organizationId: string, workspaceId: string, clusterId: string) {
  const res = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/logs`
  );
  return res.data;
}

type UseLogsOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useLogs({ config, organizationId, workspaceId, clusterId }: UseLogsOptions) {
  return useQuery({
    ...config,
    queryKey: ['logs', workspaceId, clusterId],
    queryFn: () => getLogs(organizationId, workspaceId, clusterId)
  });
}

async function getLogsPresignedUrl(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  key: string
) {
  const res = await axios.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/logs/download`,
    { 'object-key': key }
  );
  return res.data;
}

type UseLogsPresignedUrlOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
  key: string;
};

interface ILogsPresignedUrlMutationVariables {
  key: string;
}

export function UseLogsPresignedUrl({
  config,
  organizationId,
  workspaceId,
  clusterId
}: UseLogsPresignedUrlOptions) {
  return useMutation({
    ...config,
    mutationFn: ({ key }: ILogsPresignedUrlMutationVariables) =>
      getLogsPresignedUrl(organizationId, workspaceId, clusterId, key)
  });
}
