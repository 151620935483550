import React from 'react';
import DataGrid from 'react-data-grid';

import 'react-data-grid/lib/styles.css';

import { useTheme } from '@/components/theme-provider';

import { ExplainAPIResponse, QueryAPIResponse } from '../types';

import './QueryDataGrid.scss';

type ResultGridPros = {
  result: QueryAPIResponse;
  asCode: boolean;
};

export default function QueryDataGrid({ result, asCode }: ResultGridPros) {
  const { theme } = useTheme();
  const columns = result.columns.map((column: { name: any }, index: any) => ({
    key: index,
    name: column.name
  }));
  const rows = getRows(result, asCode);
  return (
    <DataGrid
      columns={columns}
      rows={rows}
      className={`h-full border border-l-0 border-base-475 ${
        theme === 'dark' ? 'rdg-dark rdg-custom-dark' : 'rdg-light'
      }`}
      headerRowHeight={50}
      rowHeight={45}
    />
  );
}

function getRows(result: ExplainAPIResponse | QueryAPIResponse, asCode: boolean) {
  if (asCode) {
    return result.rows.map((row) => {
      return row.map((cell: any, index: number) => <pre key={index}>{stringify(cell)}</pre>);
    });
  }
  return result.rows.map((row) => {
    return row.map((cell: any) => stringify(cell));
  });
}

/**
 * Convert columns of array/object data type to string.
 * @param {*} data
 * @returns data as string if array/obj
 */
function stringify(data: any) {
  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      return String(data);
    }
    return JSON.stringify(data);
  }
  return data;
}
