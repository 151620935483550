import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { useToast } from '@/components/ui/use-toast';

import { useRestoreBackup } from '../api/RestoreApi';

type RestoreBackupProps = {
  backupId: number;
};

export default function RestoreBackup({ backupId }: RestoreBackupProps) {
  const { organization_id, workspace_id, cluster_id } = useParams();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const [renameDb, setRenameDb] = useState(false);

  const restoreBackupMutation = useRestoreBackup({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!,
    config: {
      onSuccess: () => {
        queryClient.invalidateQueries('backups');
        toast({
          title: 'Restore Backup success'
        });
      },
      onError: () => {
        toast({ title: 'Restore Backup Failed', variant: 'destructive' });
      }
    }
  });

  function handleRestore() {
    restoreBackupMutation.mutate({ backup_id: backupId, rename_original_database: renameDb });
  }

  function handleToggle() {
    setRenameDb(!renameDb);
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant={'secondary'}>Restore</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Restore Backup</AlertDialogTitle>
          <AlertDialogDescription className="text-left">
            ClickHouse default restore will insert the data to existing tables without clearing the
            old data. You can choose to restore this backup with a different name.
          </AlertDialogDescription>
          <div className="flex items-center gap-2 w-full pt-2">
            <Switch id="same-address" onCheckedChange={handleToggle} />
            <Label htmlFor="same-address">Restore as different database</Label>
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction asChild onClick={handleRestore}>
            <Button>Restore</Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
