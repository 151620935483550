import { useQuery } from 'react-query';

import { axios } from '@/shared/utils';

async function getClusterRoles(organizationId: string, workspaceId: string, clusterId: string) {
  const res = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/roles`
  );
  return res.data;
}

type UseClusterRolesOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useClusterRoles({
  config,
  organizationId,
  workspaceId,
  clusterId
}: UseClusterRolesOptions) {
  return useQuery({
    ...config,
    queryKey: 'cluster-roles',
    queryFn: () => getClusterRoles(organizationId, workspaceId, clusterId)
  });
}
