export default function NDA() {
  function handleClick() {
    fetch(`${window.location.origin}/chistadata-nda.pdf`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'chistadata-nda.pdf';
        alink.click();
      });
    });
  }
  return (
    <div className="w-full" onClick={handleClick}>
      NDA
    </div>
  );
}
