import React from 'react';

export type BaseButtonProps = {
  onClick?: any;
  type?: any;
  disabled?: any;
};

export function Base({
  className,
  children,
  ...props
}: BaseButtonProps & { className?: string; children?: React.ReactNode }) {
  return (
    <button className={'btn ' + className} {...props}>
      {children}
    </button>
  );
}
