import ClusterDeleteDialog from './ClusterDeleteDailog';

type ClusterDeleteCardProps = {
  cluster: any;
};
export default function ClusterDeleteCard({ cluster }: ClusterDeleteCardProps) {
  return (
    <div className="border border-error-100 rounded px-4 py-6">
      <div className="text-error">Delete Cluster</div>
      <div className="flex justify-between items-center">
        <div className="text-sm">
          This will delete Database, Data and Backups.This action cannot be undone
        </div>
        <ClusterDeleteDialog cluster={cluster} />
      </div>
    </div>
  );
}
