import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { useToast } from '@/components/ui/use-toast';
import { useUpdateOrganizationAddress } from '@/pages/Organization/api/OrganizationApi';

const formSchema = z.object({
  email: z.string().min(1, { message: 'Email is required' }).email({
    message: 'Invalid email address'
  }),
  street: z.string().min(1, { message: 'Street is required' }),
  city: z.string().min(1, { message: 'City is required' }),
  state: z.string().min(1, { message: 'State is required' }),
  zip: z.string().min(1, { message: 'Zip is required' }),
  country: z.string().min(1, { message: 'Country is required' }),
  billingEmail: z.string({}),
  billingStreet: z.string({}),
  billingCity: z.string({}),
  billingState: z.string({}),
  billingZip: z.string({}),
  billingCountry: z.string({})
});

type AddressForm = {
  organizationId: string;
};

export default function AddressForm({ organizationId }: AddressForm) {
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [sameAddress, setSameAddress] = useState(true);
  const updateAddressMutation = useUpdateOrganizationAddress({
    organizationId,
    config: {
      onSuccess: () => {
        navigate(-1);
        queryClient.invalidateQueries(['workspaces']);
        toast({
          title: 'Success',
          description: 'Address Updated'
        });
      },
      onError: () => {
        queryClient.invalidateQueries(['workspaces']);
        toast({
          title: 'Failed',
          description: 'Unable to update address'
        });
      }
    }
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      state: '',
      street: '',
      city: '',
      country: '',
      zip: '',
      billingEmail: '',
      billingState: '',
      billingStreet: '',
      billingCity: '',
      billingCountry: '',
      billingZip: ''
    }
  });
  function onSubmit(values: z.infer<typeof formSchema>) {
    const data = {
      adminAddress: {
        organization_id: Number(organizationId),
        address_type: 'admin' as const,
        email: values.email,
        state: values.state,
        street: values.street,
        city: values.city,
        country: values.country,
        zip_code: values.zip
      },
      billingAddress: {
        organization_id: Number(organizationId),
        address_type: 'billing' as const,
        email: values.email,
        state: values.state,
        street: values.street,
        city: values.city,
        country: values.country,
        zip_code: values.zip
      }
    };
    if (!sameAddress) {
      data.billingAddress.email = values.billingEmail;
      data.billingAddress.state = values.billingState;
      data.billingAddress.street = values.billingStreet;
      data.billingAddress.city = values.billingCity;
      data.billingAddress.zip_code = values.billingZip;
      data.billingAddress.country = values.billingCountry;
    }
    updateAddressMutation.mutate(data);
  }
  function handleToggle() {
    setSameAddress(!sameAddress);
  }
  return (
    <>
      <Form {...form}>
        <form className="flex flex-wrap gap-2" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="john.doe@xyz.com" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="street"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Street</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem>
                <FormLabel>City</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="state"
            render={({ field }) => (
              <FormItem>
                <FormLabel>State</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="zip"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Zip</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Country</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex items-center space-x-2 w-full">
            <Switch id="same-address" onCheckedChange={handleToggle} defaultChecked={true} />
            <Label htmlFor="same-address">Billing address is same as admin address</Label>
          </div>

          {!sameAddress && (
            <>
              <FormField
                control={form.control}
                name="billingEmail"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="john.doe@xyz.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="billingStreet"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Street</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="billingCity"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>City</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="billingState"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>State</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="billingZip"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Zip</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="billingCountry"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Country</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}

          <div className="w-full flex justify-end gap-2">
            <Button type="submit" disabled={!form.formState.isValid}>
              Update Address
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}
