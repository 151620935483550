import { Dialog, Transition } from '@headlessui/react';
import React, { FormEventHandler, Fragment, useCallback } from 'react';

import { Button } from '@/shared/components/elements/Button';
// import { AiOutlineClose } from 'react-icons/ai';
import {
  IDynamicModalStore,
  useDynamicModalHooks,
  useStore as useDynamicModalStore
} from '@/shared/store/dynamic-modal';

/**
 * DynamicModal - This component will act as the wrapper for different modals
 * used in the application and can be invoked dynamically via the context store.
 */
export default function DynamicModal() {
  const [store] = useDynamicModalStore<IDynamicModalStore>();
  const {
    title,
    titleIcon,
    content,
    isOpen,
    cancelButtonText,
    cancelButtonClassName,
    submitButtonText,
    submitButtonClassName,
    containerStyle,
    validateFunc,
    successCallback,
    failureCallback,
    isFormValid,
    formRef,
    showSubmitButton
  } = store;
  const { closeModal } = useDynamicModalHooks();

  const successButtonClick = useCallback(
    async (target: EventTarget & HTMLFormElement) => {
      if (successCallback) {
        successCallback(target);
      }
    },
    [successCallback]
  );

  const cancelButtonClick = useCallback(() => {
    if (failureCallback) {
      failureCallback();
    }
    closeModal();
  }, [failureCallback]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (validateFunc) {
      const flag = validateFunc();
      if (!flag) return;
    }
    successButtonClick(event.currentTarget);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={cancelButtonClick}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full rounded max-w-md transform overflow-hidden bg-base-320 text-left align-middle shadow-xl transition-all p-9 ${containerStyle}`}
                >
                  {titleIcon && (
                    <div className="text-error flex h-10 w-10 flex-shrink-0 items-center justify-center rounded bg-error-200 mb-3">
                      {titleIcon}
                    </div>
                  )}
                  <Dialog.Title
                    as="h3"
                    className="font-bold text-lg leading-6 text-primary-900 flex justify-between mb-4"
                  >
                    {title}
                    {/* <AiOutlineClose
                      className="cursor-pointer"
                      size={'1.5em'}
                      onClick={closeModal}
                    ></AiOutlineClose> */}
                  </Dialog.Title>

                  <form ref={formRef} onSubmit={handleSubmit}>
                    {content}

                    <div className="mt-9 flex justify-end">
                      <Button.Secondary
                        type="button"
                        className={`btn btn-secondary rounded min-w-[120px] ${cancelButtonClassName ?? 'text-primary-100'} `}
                        onClick={cancelButtonClick}
                      >
                        {cancelButtonText ?? 'Cancel'}
                      </Button.Secondary>
                      {showSubmitButton && (
                        <Button.Primary
                          type="submit"
                          disabled={!isFormValid}
                          className={`${
                            !isFormValid ? 'opacity-50 cursor-not-allowed ' : ''
                          } ml-2 min-w-[120px] ${submitButtonClassName ?? ''}`}
                        >
                          {submitButtonText ?? 'Ok'}
                        </Button.Primary>
                      )}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
