import { atom } from 'chista-state-manager';
import { MouseEventHandler } from 'react';

export interface IContextMenu {
  open: boolean;
  menuItems?: Array<{
    title: string;
    onClick: MouseEventHandler;
  }>;
  position?: {
    x: number;
    y: number;
  };
}

export const contextMenu$ = atom<IContextMenu | null>(null);

export function closeContextMenu() {
  contextMenu$.set({
    open: false,
    menuItems: undefined,
    position: undefined
  });
}
