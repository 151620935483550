import React from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useParams } from 'react-router-dom';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { useUserDetails } from '@/shared/api/InitApis';
import '@/shared/components/elements/Menu/styles.css';
import { GetPermissionNode } from '@/shared/utils/authorization/authUtils';

import { IWorkspace } from '../types';

import DeleteWorkspace from './DeleteWorkspace';

type Props = {
  workspace: IWorkspace;
};

export default function WorkspaceDropDown({ workspace }: Props) {
  const { organization_id } = useParams();

  const user = useUserDetails({ organizationId: organization_id! });

  // let canEdit = false;
  let canDelete = false;

  if (user.data) {
    const permRoot = new Map(Object.entries(user.data.permissions));
    const workspacePerms = GetPermissionNode(permRoot, ['workspaces', `id-${workspace.id}`]);

    // canEdit = workspacePerms?.has('edit');
    canDelete = workspacePerms?.has('delete');
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <BsThreeDotsVertical className="w-5 h-5 text-primary" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {/* {canEdit && <DropdownMenuItem onClick={editWorkspace}>Edit</DropdownMenuItem>} */}
        {canDelete && <DeleteWorkspace organizationId={organization_id!} workspace={workspace} />}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
