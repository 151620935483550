import { useParams } from 'react-router-dom';

import { InviteUserForm } from './components/InviteUserForm';

export default function InviteUser() {
  const { organization_id } = useParams();

  return (
    <div className="p-4 flex items-center flex-col w-full h-full overflow-auto">
      <div className="max-w-md w-full">
        <div className="text-xl font-bold py-4 w-full px-2">Invite User</div>
        <div className="border-b-2 border-border h-1 w-full mb-3"></div>
        <div className="w-full">
          <InviteUserForm orgId={organization_id!} />
        </div>
      </div>
    </div>
  );
}
