import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FiCheck } from 'react-icons/fi';
import * as z from 'zod';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Spinner } from '@/components/ui/spinner';
import { toast } from '@/components/ui/use-toast';

import { useSendUserInvite } from '../api/UserManagementApi';
import getPermissionStructure from '../utility/userRolePermission';

const formSchema = z.object({
  username: z.string().min(2, {
    message: 'Username must be at least 2 characters.'
  }),
  email: z
    .string({
      required_error: 'Enter a valid email'
    })
    .email(),
  role: z
    .string({
      required_error: 'Please select a role.'
    })
    .min(1, {
      message: 'Select a role'
    })
});

type InviteFormProps = {
  orgId: string;
};

export function InviteUserForm({ orgId }: InviteFormProps) {
  const sendInviteMutation = useSendUserInvite({
    organizationId: orgId,
    config: {
      onSuccess: () => {
        toast({ title: 'Invite sent' });
      },
      onError: () => {
        toast({
          variant: 'destructive',
          title: 'Invite failed'
        });
      }
    }
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
      email: '',
      role: ''
    }
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    const permissions = getPermissionStructure(values.role);
    const data = {
      name: values.username,
      email: values.email,
      organization_id: orgId,
      permissions
    };
    sendInviteMutation.mutate(data);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input placeholder="john doe" {...field} />
              </FormControl>
              <FormDescription>This is your public display name.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input type="email" placeholder="john.doe@xyz.org" {...field} />
              </FormControl>
              <FormDescription>The invite will be sent to this email.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Permission</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select user role" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="orgAdmin">Organization Admin</SelectItem>
                  <SelectItem value="workspaceAdmin">Workspace Admin</SelectItem>
                  <SelectItem value="readOnly">Read-only user</SelectItem>
                </SelectContent>
              </Select>
              <FormDescription>
                Learn more about
                <span className="text-primary">
                  <a
                    href="https://docs.cloud.chistadata.io/getting-started/rbac-management/index.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    user roles
                  </a>
                  .
                </span>
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="w-full flex justify-end gap-2">
          {sendInviteMutation.isLoading && (
            <Button className="w-24" disabled>
              <Spinner variant={'button'} />
            </Button>
          )}
          {sendInviteMutation.isSuccess && (
            <Button className="w-24 pointer-events-none">
              <FiCheck />
            </Button>
          )}
          {sendInviteMutation.isError && (
            <Button variant={'destructive'} disabled className="w-24">
              x
            </Button>
          )}
          {sendInviteMutation.isIdle && (
            <Button type="submit" className="w-24">
              Send Invite
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
}
