import React, { useEffect, useState } from 'react';
import { BsTable } from 'react-icons/bs';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useTheme } from '@/components/theme-provider';
import { Spinner } from '@/components/ui/spinner';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import CopyToClipboardButton from '@/pages/ClusterSettings/components/CopyToClipboardButton';
import Metadata from '@/pages/Metadata/Metadata';
// import { Tab, TabHeader, TabHeaders, TabPanel, TabPanels } from '@/shared/components/elements/Tab';
import { IQueryEditorStore, useStore as useQueryStore } from '@/shared/store/query-editor';

import useCredentials from '../ClusterList/api/CredentialsApi';

import { useDBQuery } from './api/QueryEditorApi';
import QueryDataGrid from './components/QueryDataGrid';
import QueryEditorTopPanel from './components/QueryEditorTopPanel';
import QueryStatsPanel from './components/QueryStatusPanel';
import SQLEditor from './components/SQLEditor';
import { ExplainAPIResponse, QueryAPIResponse, QueryStats } from './types';

function getCredentialId(data: any) {
  let credentailId = null;
  if (data.preferred_credential !== null) {
    credentailId = data.preferred_credential.credential_id;
  }
  return credentailId;
}

export default function QueryEditor() {
  const { workspace_id, cluster_id, organization_id } = useParams();
  const currentTheme = useTheme();
  const queryClient = useQueryClient();

  // set query result in global store
  const [queryEditorStore, setQueryEditorStore] = useQueryStore<IQueryEditorStore>();
  const { query: initial, result, explain } = queryEditorStore;

  const [query, setQuery] = useState(initial);

  const { data, isLoading, error }: any = useCredentials({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!
  });

  const credentialId = data && getCredentialId(data);

  function handleHotkey(event: KeyboardEvent) {
    // const target = event.target as HTMLInputElement;
    // // const parentId = target?.parentElement?.id;
    // // console.log(target);
    // // if (parentId !== 'query-editor') {
    // //   return;
    // // }
    const enterKey = event.key === 'Enter';
    const ctrlKey = event.ctrlKey;
    const shiftKey = event.shiftKey;
    if (enterKey && ctrlKey) {
      if (shiftKey) {
        onExplain();
      } else {
        onExecute();
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleHotkey);
    return () => {
      window.removeEventListener('keydown', handleHotkey);
    };
  });

  const runOrExplainQuery = useDBQuery({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!,
    credentialId: credentialId,
    config: {
      onSuccess: (data: any, variables: any) => {
        setQueryEditorStore({
          result: data,
          explain: variables.explain
        });
        if (data?.columns?.length === 0) {
          queryClient.invalidateQueries('completion');
        }
      }
    }
  });

  function onChange(newValue: string) {
    setQuery(newValue);
  }

  function onExplain() {
    if (runOrExplainQuery.isLoading || !query) {
      return;
    }
    runOrExplainQuery.mutate({ query, explain: true });
  }

  function onExecute() {
    if (runOrExplainQuery.isLoading || !query) {
      return;
    }
    runOrExplainQuery.mutate({ query, explain: false });
  }

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (data) {
    return (
      <div className="flex h-full w-full overflow-hidden flex-1 border pb-5 bg-background border-border">
        <div className="w-[25%] font-sans">
          <Metadata
            clusterId={cluster_id!}
            workspaceId={workspace_id!}
            organizationId={organization_id!}
            credentialId={credentialId}
          />
        </div>

        <div className="w-[75%] flex flex-col bg-base-450 border-b border-border font-mono">
          <QueryEditorTopPanel
            submitting={runOrExplainQuery.isLoading}
            onExecute={onExecute}
            onExplain={onExplain}
          />
          <SQLEditor onChange={onChange} theme={currentTheme.theme} />
          <QueryStatsPanel
            queryStats={getStats(result)}
            activeQuery={query}
            credentialId={credentialId}
          />
          <div className="flex flex-1 overflow-hidden w-[100%]">
            <Result
              result={result || runOrExplainQuery.data}
              submitting={runOrExplainQuery.isLoading}
              error={runOrExplainQuery.error}
              explain={explain || runOrExplainQuery.variables?.explain}
              theme={currentTheme.theme}
            />
          </div>
        </div>
      </div>
    );
  }

  return <div>{error?.message}</div>;
}

type ResultProps = {
  result: ExplainAPIResponse[] | QueryAPIResponse;
  submitting: boolean;
  explain: boolean | undefined;
  error: any;
  theme: string;
};
function Result({ result, submitting, explain, error }: ResultProps) {
  if (submitting) {
    return (
      <div className="w-full place-content-center grid">
        <Spinner />
      </div>
    );
  }
  if (!result) {
    return (
      <div className="px-2 flex flex-1 justify-center h-full items-center opacity-60 flex-col">
        <BsTable size={'1.8em'} className="pb-2" />
        <div className="text-sm">Execute a query to see the results</div>
      </div>
    );
  }
  if (error) {
    return (
      <div className="w-full place-content-center items-center flex text-error">
        <span className="font-semibold uppercase">Error</span>: {error}
      </div>
    );
  }
  if (explain) {
    return (
      <div className="w-full h-full">
        <ExplainResultGrid results={result as ExplainAPIResponse[]} />
      </div>
    );
  }
  return (
    <div className="w-full">
      <ExecuteResultGrid result={result as QueryAPIResponse} />
    </div>
  );
}

function ExplainResultGrid({ results }: { results: ExplainAPIResponse[] }) {
  const titles = results.map((result: ExplainAPIResponse) => {
    return result.title;
  });

  const [currentTab, setCurrentTab] = useState(titles[0]);

  return (
    <Tabs defaultValue={titles[0]} onValueChange={(v) => setCurrentTab(v)} className="h-full">
      <div className="h-8 flex justify-between">
        <TabsList>
          {titles.map((v) => (
            <TabsTrigger value={v} key={v}>
              {v}
            </TabsTrigger>
          ))}
        </TabsList>
        {currentTab === 'Plan' && results[0].rows && (
          <div className="flex justify-center items-center pr-2">
            <CopyExplainButton content={results[0].rows} />
          </div>
        )}
      </div>
      <div className="h-full pb-10">
        {results.map((v) => (
          <TabsContent value={v.title} key={v.title} className="h-full overflow-auto">
            {v.success ? (
              <QueryDataGrid key={v.title} result={v} asCode={true} />
            ) : (
              <div className="overflow-auto h-full">
                <ErrorMessage error={v.message} />
              </div>
            )}
          </TabsContent>
        ))}
      </div>
    </Tabs>
  );
}

function CopyExplainButton({ content }: { content: any }) {
  const joinedContent = content.join('\n');
  return <CopyToClipboardButton content={joinedContent} />;
}

function ErrorMessage({ error }: any) {
  return (
    <div className="p-4 w-full h-full place-content-center flex text-destructive">
      <span className="font-semibold uppercase">Error</span>: {error}
    </div>
  );
}

function InfoMessage({ message }: { message: string }) {
  return (
    <div className="px-2 flex flex-1 justify-center h-full items-center opacity-60 flex-col">
      <div>{message}</div>
    </div>
  );
}

function ExecuteResultGrid({ result }: { result: QueryAPIResponse }) {
  if (!result?.success) {
    return (
      <div className="overflow-auto h-full border-t border-base-475">
        <ErrorMessage error={result.message} />
      </div>
    );
  }
  if (result.columns.length === 0) {
    return <InfoMessage message="The query completed successfully without errors and warnings" />;
  }
  return <QueryDataGrid result={result} asCode={false} />;
}

function getStats(result: ExplainAPIResponse[] | QueryAPIResponse | undefined): QueryStats | null {
  if (result === undefined) {
    return null;
  }
  return result as QueryStats;
}

// function refetchCompletions() {
//   const queryClient = useQueryClient();
//   queryClient.invalidateQueries('completion');
// }
