import React, { useState } from 'react';
import { BsTable, BsLayoutThreeColumns } from 'react-icons/bs';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';

import { axios } from '@/shared/utils/axios';

export function Table({
  schema,
  name,
  clusterId,
  workspaceId,
  organizationId,
  preferredCredentialId
}: any) {
  const [columns, setColumns] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const handleTableClick = () => {
    if (loaded) {
      setLoaded(false);
    } else {
      getColumns();
      setLoaded(true);
    }
  };

  async function getColumns() {
    const response = await axios.get(
      `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/metadata/schemas/${schema}/tables/${name}/columns?credential_id=${preferredCredentialId}`
    );
    setColumns(response.data);
    setLoaded(true);
  }
  return (
    <div className="pl-4 pb-1 w-full">
      <div className="flex" onClick={handleTableClick}>
        <button>{loaded ? <FiChevronDown /> : <FiChevronRight />}</button>
        <div className="flex items-center pl-2">
          <BsTable className="pr-1 text-primary-600" />
          <span className="pl-3">{name}</span>
        </div>
      </div>
      <div>
        {loaded &&
          columns.map((item: any) => {
            return <Column key={`${schema}.${name}.${item.name}`} {...item} />;
          })}
      </div>
    </div>
  );
}

function Column({ name }: any) {
  return (
    <div className="ml-6 pb-1">
      <div className="flex items-center">
        <BsLayoutThreeColumns className="pr-1 text-primary-600" />
        <span className="pl-3">{name}</span>
      </div>
    </div>
  );
}
