import React from 'react';
import { RiCheckboxLine } from 'react-icons/ri';
import { VscError } from 'react-icons/vsc';

type Props = {
  message: string;
  hideAfter?: number;
  hide: any;
};

type AutoHideParams = {
  hide: any;
  hideAfter?: number;
  children: React.ReactNode;
};

function AutoHide({ hideAfter = 3000, hide, children }: AutoHideParams) {
  if (hideAfter > 0) {
    setTimeout(hide, hideAfter);
  }
  return <>{children}</>;
}

export function Success(props: Props) {
  return (
    <AutoHide hide={props.hide} hideAfter={props.hideAfter}>
      <div className="z-[999] toast toast-top toast-end">
        <div className="alert alert-success rounded-md bg-success-100 border border-success h-[45px] min-w-[250px]">
          <div>
            <RiCheckboxLine className="h-6 w-6 text-success" />
            <span className="text-base-900">{props.message}</span>
          </div>
        </div>
      </div>
    </AutoHide>
  );
}

export function Error(props: Props) {
  return (
    <AutoHide hide={props.hide} hideAfter={props.hideAfter}>
      <div className="z-[999] toast toast-top toast-end">
        <div className="alert alert-error bg-error-content border border-error rounded-md h-[45px] min-w-[250px]">
          <div>
            <VscError className="h-6 w-6 text-error" />
            <span className="text-base-900">{props.message}</span>
          </div>
        </div>
      </div>
    </AutoHide>
  );
}
