export enum InputType {
  TEXT,
  PASSWORD,
  TEXT_AREA,
  TOGGLE,
  SELECT,
  CHECKBOX
}

export interface FormItem {
  name: string;
  label: string;
  defaultValue?: string | number | boolean;
  type: InputType;
  placeholder?: string;
  options?: Array<{ name: string; value: string }>;
}
