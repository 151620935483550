import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/shared/components/elements/Button';

import dbLogo from '../assets/dbLogo.jpeg';
import { IOrg } from '../types';

type Props = { organizationList: IOrg[] };

export default function OrgSelectionModal({ organizationList }: Props) {
  const navigate = useNavigate();
  const [activeOrg, setActiveOrg] = useState<IOrg | null>(null);

  function handleClick(org: IOrg) {
    setActiveOrg(org);
  }

  function handleOrgSelect() {
    if (activeOrg !== null) {
      navigate(`${activeOrg.organization_id}`);
    }
  }

  const logoContainer = (
    <div className="flex justify-center items-center">
      <img className="h-24" src={dbLogo} alt="db-logo" />
      <div className="text-4xl">ChistaDATA</div>
    </div>
  );

  return (
    <div>
      {logoContainer}
      <div className="flex items-center justify-center text-center max-w-[600px]">
        <div className="flex flex-col w-full p-12 rounded shadow-lg">
          <div className="bold text-lg">Select Organization</div>
          <div className="border overflow-auto w-[400px] max-h-[165px]">
            {organizationList.map((org) => {
              const isActiveOrg = activeOrg === org;
              const baseStyle = 'w-full cursor-pointer p-2 min-w-5 ';
              const activeStyle = 'bg-primary/90 text-primary-foreground';
              return (
                <div
                  key={org.organization_id}
                  className={
                    isActiveOrg ? baseStyle + activeStyle : baseStyle + 'hover:bg-gray-300'
                  }
                  onClick={() => handleClick(org)}
                >
                  {org.organization_name}
                </div>
              );
            })}
          </div>

          <Button.Primary
            onClick={handleOrgSelect}
            disabled={!activeOrg}
            className={
              activeOrg
                ? 'items-center justify-center h-12 px-6 mt-8 text-sm font-semibold rounded bg-primary text-primary-foreground hover:bg-primary/70'
                : 'items-center justify-center h-12 px-6 mt-8 text-sm font-semibold rounded bg-gray-300 text-gray-100'
            }
          >
            Select Organization
          </Button.Primary>
        </div>
      </div>
    </div>
  );
}
