import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';

import { OrganizationDetail, AddressDTO, OrganizationDetailDTO } from '@/pages/Settings/types';
import { axios } from '@/shared/utils/axios';

import { BillingHistory } from '../types';

export async function getOrganizations() {
  const res = await axios.get('/organizations');
  return res.data;
}

type UseOrganizationsOptions = {
  config?: object;
};

export function UseOrganizations({ config }: UseOrganizationsOptions = {}) {
  return useQuery({
    ...config,
    queryKey: ['organizations'],
    queryFn: getOrganizations
  });
}

export function useOrg(organization_id: string) {
  const orgList = UseOrganizations();
  let activeOrg;
  if (orgList.data) {
    const index = orgList.data.findIndex(
      (org: any) => org.organization_id === parseInt(organization_id)
    );
    activeOrg = orgList.data[index];
  }
  return activeOrg;
}

export async function getOrganizationDetails(organizationsId: string): Promise<OrganizationDetail> {
  const res = await axios.get(`/organizations/${organizationsId}`);
  return res.data;
}

type UseOrganizationDetailsOptions = {
  config?: object;
  organizationId: string;
};

export function useOrganizationDetails({ config, organizationId }: UseOrganizationDetailsOptions) {
  return useQuery({
    ...config,
    queryKey: ['organization-details', organizationId],
    queryFn: async () => await getOrganizationDetails(organizationId)
  });
}

export async function saveOrganizationDetails(
  organizationsId: string,
  input: OrganizationDetailDTO
) {
  const res = await axios.put(`/organizations/${organizationsId}`, input);
  return res.data;
}

export function useUpdateOrganizationDetailsMutation(
  organization_id: string,
  successCallback: (data: any) => void,
  errorCallback: (err: AxiosError<{ message: string }>) => void
) {
  return useMutation(
    async (input: OrganizationDetailDTO) => await saveOrganizationDetails(organization_id, input),
    {
      onSuccess: successCallback,
      onError: errorCallback
    }
  );
}

export async function createOrganizationSubscriptionLink(organizationsId: string, tier: string) {
  const res = await axios.post(`/organizations/${organizationsId}/subscriptions`, { tier });
  return res.data;
}

export function useOrganizationSubscriptionLinkMutation(
  organization_id: string,
  successCallback: (data: any) => void,
  errorCallback: (err: AxiosError<{ message: string }>) => void
) {
  return useMutation(
    async (tier: string) => await createOrganizationSubscriptionLink(organization_id, tier),
    {
      onSuccess: successCallback,
      onError: errorCallback
    }
  );
}

export async function getOrganizationAddress(
  organizationsId: string,
  address_type: 'admin' | 'billing'
) {
  const res = await axios.get(`/organizations/${organizationsId}/addresses/${address_type}`);
  return res.data;
}

export function useOrganizationAddressQuery(
  organization_id: string,
  address_type: 'admin' | 'billing'
) {
  return useQuery(
    ['organization-address', organization_id, address_type],
    async () => await getOrganizationAddress(organization_id, address_type),
    {
      retry: 0
    }
  );
}

export async function updateOrganizationAddress(organizationsId: string, address: AddressDTO) {
  const res = await axios.put(`/organizations/${organizationsId}/addresses`, address);
  return res.data;
}

type UseUpdateOrganizationAddressConfig = {
  config?: object;
  organizationId: string;
};

export function useUpdateOrganizationAddress({
  config,
  organizationId
}: UseUpdateOrganizationAddressConfig) {
  return useMutation({
    ...config,
    mutationFn: async ({
      adminAddress,
      billingAddress
    }: {
      adminAddress: AddressDTO;
      billingAddress: AddressDTO;
    }) => {
      return await Promise.all([
        updateOrganizationAddress(organizationId, adminAddress),
        updateOrganizationAddress(organizationId, billingAddress)
      ]);
    }
  });
}

export async function getOrganizationActiveSubscription(organizationsId: string) {
  const res = await axios.get(`/organizations/${organizationsId}/subscriptions/active`);
  return res.data;
}

export function useOrganizationActiveSubscriptionQuery(organization_id: string) {
  return useQuery(
    ['organization-active-subscription', organization_id],
    async () => await getOrganizationActiveSubscription(organization_id),
    {
      retry: 0
    }
  );
}

export async function getBillingHistory(organizationsId: string): Promise<BillingHistory[]> {
  const res = await axios.get(`/organizations/${organizationsId}/subscriptions/invoices`);
  return res.data;
  // return await Promise.resolve([
  //   {
  //     id: 1,
  //     date: '2023-02-25T14:23:31.552Z',
  //     amount: 19900,
  //     tier_name: 'startup',
  //     status: 'success',
  //     download_link: 'http://platform.local/api/organizations/69/subscriptions/invoices/1/pdf'
  //   }
  // ]);
}

export function useBillingHistory(organizationsId: string) {
  return useQuery(
    ['billing-history', organizationsId],
    async () => await getBillingHistory(organizationsId)
  );
}

interface SubscriptionValidityResponse {
  show_banner: boolean;
  end_date: string;
}

export async function getOrganizationSubscriptionValidity(
  organization_id: string
): Promise<SubscriptionValidityResponse> {
  const res = await axios.get(`/organizations/${organization_id}/subscriptions/validity`);
  return res.data;
}

export function useOrganizationSubscriptionValidity(organization_id: string) {
  return useQuery(
    ['organization-subscription-validity', organization_id],
    async () => await getOrganizationSubscriptionValidity(organization_id)
  );
}
