import { Ref } from 'react';

import createFastContext from '@/shared/utils/CreateFastReactContext';

export interface OpenModalParams {
  title: string;
  titleIcon?: any;
  content: any;
  cancelButtonText?: string;
  cancelButtonClassName?: string;
  submitButtonText?: string;
  submitButtonClassName?: string;
  containerStyle?: string;
  successCallback?: (args: EventTarget & HTMLFormElement) => void;
  failureCallback?: () => void;
  formRef?: Ref<any>;
  validateFunc?: () => boolean;
  showSubmitButton?: boolean;
}
export interface IDynamicModalStore extends OpenModalParams {
  isOpen: boolean;
  isFormValid: boolean;
}
/**
 * This store context will be used to open modal
 * dynamically and populate it's content.
 */
const { Provider, useStore } = createFastContext<IDynamicModalStore>({
  title: '',
  titleIcon: null,
  content: null,
  isOpen: false,
  isFormValid: false,
  formRef: undefined,
  cancelButtonText: undefined,
  cancelButtonClassName: undefined,
  submitButtonText: undefined,
  submitButtonClassName: undefined,
  containerStyle: undefined,
  validateFunc: undefined
});

export function useDynamicModalHooks() {
  const [, setDynamicModalStore] = useStore();

  const closeModal = () => {
    setDynamicModalStore({ isOpen: false });
    setTimeout(() => {
      setDynamicModalStore({
        title: '',
        titleIcon: null,
        content: null,
        successCallback: undefined,
        failureCallback: undefined,
        containerStyle: undefined,
        validateFunc: undefined
      });
    }, 250);
  };

  const openModal = ({
    title,
    titleIcon,
    content,
    successCallback,
    failureCallback,
    formRef,
    cancelButtonText,
    cancelButtonClassName,
    submitButtonText,
    submitButtonClassName,
    containerStyle,
    validateFunc,
    showSubmitButton = true
  }: OpenModalParams) => {
    setDynamicModalStore({
      title,
      titleIcon,
      content,
      isOpen: true,
      isFormValid: true,
      successCallback,
      failureCallback,
      formRef,
      cancelButtonText,
      cancelButtonClassName,
      submitButtonText,
      submitButtonClassName,
      containerStyle,
      validateFunc,
      showSubmitButton
    });
  };

  const setFormValid = (value: boolean) => {
    setDynamicModalStore({ isFormValid: value });
  };

  const setFormLoading = (loadingText: string) => {
    setDynamicModalStore({ isFormValid: false, submitButtonText: loadingText });
  };

  const clearFormLoading = (loadedText: string) => {
    setDynamicModalStore({ isFormValid: true, submitButtonText: loadedText });
  };

  const updateModalContent = (content: any) => {
    setDynamicModalStore({ content });
  };

  return {
    closeModal,
    openModal,
    setFormValid,
    setFormLoading,
    clearFormLoading,
    updateModalContent
  };
}

export { Provider, useStore };
