import { useUserDetails } from '@/shared/api';
import { GetPermissionNode } from '@/shared/utils';
import { PermItem } from '@/types';

import { IWorkspace } from '../types';

type UseWorkspacePermissionOptions = {
  organizationId: string;
};

export default function UseWorkspacePermission({ organizationId }: UseWorkspacePermissionOptions) {
  const user = useUserDetails({ organizationId: organizationId });

  let quotaRemaining = 0;
  let canCreateWorkspace = false;
  let canManageSettings = false;
  if (user.data) {
    const permRoot = new Map(Object.entries(user.data?.permissions));
    const workspacePerms = GetPermissionNode(permRoot, ['workspaces']);

    canCreateWorkspace = workspacePerms?.has('create');
    quotaRemaining = user.data.quota_remaining.workspaces_remaining;

    const organizationPerms = GetPermissionNode(permRoot, ['organization', 'details']);
    canManageSettings = organizationPerms?.has('edit');
  }

  return {
    isLoading: user.isLoading,
    isError: user.isError,
    canCreateWorkspace,
    quotaRemaining,
    canManageSettings,
    checkWorkspacePermission: (workspace: IWorkspace) =>
      checkWorkspaceAccessPermission(user, workspace)
  };
}

export function checkWorkspaceAccessPermission(user: any, workspace: IWorkspace): boolean {
  let currentPermRoot: PermItem;
  if (user.data) {
    const permRoot = new Map(Object.entries(user.data.permissions));
    const accessPath = ['workspaces'];
    currentPermRoot = GetPermissionNode(permRoot, accessPath);
    return currentPermRoot?.has(`id-${workspace.id}`);
  } else {
    return false;
  }
}
