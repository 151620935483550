import React, { useRef, useState } from 'react';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { TfiReload } from 'react-icons/tfi';
import { useQueryClient } from 'react-query';

import { Spinner } from '@/components/ui/spinner';
import { ICluster } from '@/pages/ClusterList/types';

import { useClusters } from '../ClusterList/api/ClusterApi';

import { useSchemas } from './api/MetadataApi';
import MetadataControlPanel from './components/MetadataControlPanel';
import { Schema } from './components/Schema';

type MetadataProps = {
  workspaceId: string;
  clusterId: string;
  organizationId: string;
  credentialId: string;
};

export default function Metadata({
  clusterId,
  workspaceId,
  organizationId,
  credentialId
}: MetadataProps) {
  const [showDatabases, setShowDatabases] = useState(true);
  const queryClient = useQueryClient();
  const databasesHeaderDivRef = useRef(null);
  const databasesBodyDivRef = useRef(null);

  const {
    data: schemas,
    isError: isSchemasError,
    isLoading: isSchemasLoading
  } = useSchemas({
    organizationId,
    workspaceId,
    clusterId,
    credentialId
  });

  const {
    data: clusterListData,
    isLoading: isClusterListLoading,
    isError: isClusterListError
  } = useClusters({ organizationId: organizationId!, workspaceId: workspaceId! });

  const handleShowDatabasesClick = async () => {
    if (showDatabases) {
      setShowDatabases(false);
    } else {
      await queryClient.invalidateQueries('schemas');
      setShowDatabases(true);
    }
  };

  const refreshTree = async () => {
    setShowDatabases(false);
    await queryClient.invalidateQueries('schemas');
    setShowDatabases(true);
  };

  if (isSchemasLoading || isClusterListLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isSchemasError || isClusterListError) {
    return <div className="flex flex-1 items-center justify-center">Error</div>;
  }

  const activeCluster = clusterListData.find(
    (cluster: ICluster) => String(cluster.id) === clusterId
  );
  const clusterName = activeCluster?.name || 'Metadata';
  return (
    <>
      <div className="flex flex-col h-full border-r border-base-475 bg-base-400">
        <div className="border-b border-base-475">
          <div className="flex items-center p-3">
            <MetadataControlPanel clusterName={clusterName} credentialId={credentialId} />
          </div>
        </div>

        <div
          className="flex items-center justify-between pr-8 pl-3 py-3"
          ref={databasesHeaderDivRef}
        >
          <div className="flex items-center">
            <button onClick={handleShowDatabasesClick}>
              {showDatabases ? <FiChevronDown /> : <FiChevronRight />}
            </button>
            <span className="text-sm pl-[6px] font-bold">Databases</span>
          </div>
          <button onClick={refreshTree}>
            <TfiReload />
          </button>
        </div>
        <div className="overflow-auto h-full text-sm px-4 py-1" ref={databasesBodyDivRef}>
          <div className="w-max">
            {showDatabases &&
              schemas.map((item: any) => {
                return (
                  <Schema
                    key={item.schema}
                    {...item}
                    clusterId={clusterId}
                    workspaceId={workspaceId}
                    organizationId={organizationId}
                    preferredCredentialId={credentialId}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
