import { useParams } from 'react-router-dom';

import { Spinner } from '@/components/ui/spinner';

import useCredentials from '../ClusterList/api/CredentialsApi';

import CreateBackupForm from './components/CreateBackupForm';

export default function CreateBackup() {
  const { organization_id, workspace_id, cluster_id } = useParams();
  const { data, isLoading, isError }: any = useCredentials({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!
  });
  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>Unable to fetch credentials</div>;
  }
  return (
    <div className="p-4 flex items-center flex-col w-full">
      <div className="max-w-md w-full">
        <div className="text-xl font-bold py-4 w-full px-2">Create Backup</div>
        <div className="border-b-2 border-border h-1 w-full mb-3"></div>
        <div className="w-full">
          <CreateBackupForm credentialId={data.preferred_credential.credential_id} />
        </div>
      </div>
    </div>
  );
}
