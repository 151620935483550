import { useMutation, useQuery } from 'react-query';

import { axios } from '@/shared/utils/axios';

import { ClusterUserFormData } from '../components/CreateClusterUserForm';

async function getUserList(organizationId: string, workspaceId: string, clusterId: string) {
  const res = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/users`
  );
  return res.data;
}

type UseClusterUsersOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useClusterUsers({
  config,
  organizationId,
  workspaceId,
  clusterId
}: UseClusterUsersOptions) {
  return useQuery({
    ...config,
    queryKey: 'cluster-users',
    queryFn: () => getUserList(organizationId, workspaceId, clusterId)
  });
}

async function getClusterUser(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  username: string
) {
  const res = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/users/${username}`
  );
  return res.data;
}

type UseClusterUserOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
  username: string;
};

export function useClusterUser({
  config,
  organizationId,
  workspaceId,
  clusterId,
  username
}: UseClusterUserOptions) {
  return useQuery({
    ...config,
    queryKey: ['cluster-user', username],
    queryFn: () => getClusterUser(organizationId, workspaceId, clusterId, username)
  });
}

async function createClusterUser(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  formData: ClusterUserFormData
) {
  const res = await axios.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/users`,
    formData
  );
  return res.data;
}

type UseCreateClusterUserOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useCreateClusterUser({
  config,
  organizationId,
  workspaceId,
  clusterId
}: UseCreateClusterUserOptions) {
  return useMutation({
    ...config,
    mutationFn: (formData: ClusterUserFormData) =>
      createClusterUser(organizationId, workspaceId, clusterId, formData)
  });
}

async function updateClusterUser(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  username: string,
  formData: ClusterUserFormData
) {
  const res = await axios.patch(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/users/${username}`,
    formData
  );
  return res.data;
}

type UseUpdateClusterUserOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
  username: string;
};

export function useUpdateClusterUser({
  config,
  organizationId,
  workspaceId,
  clusterId,
  username
}: UseUpdateClusterUserOptions) {
  return useMutation({
    ...config,
    mutationFn: (formData: ClusterUserFormData) =>
      updateClusterUser(organizationId, workspaceId, clusterId, username, formData)
  });
}

async function deleteClusterUser(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  username: string
) {
  const res = await axios.delete(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/users/${username}`
  );
  return res.data;
}

type useClusterUserDeleteOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useClusterUserDeleteMutation({
  config,
  organizationId,
  workspaceId,
  clusterId
}: useClusterUserDeleteOptions) {
  return useMutation({
    ...config,
    mutationFn: (username: string) =>
      deleteClusterUser(organizationId, workspaceId, clusterId, username)
  });
}
