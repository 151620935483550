import React from 'react';
import { useParams } from 'react-router-dom';

import { Spinner } from '@/components/ui/spinner';

import { useBackupSchedules } from '../api/BackupSchedulesApi';

import DefaultBackupsTable from './DefaultBackupsTable';

export function DefaultBackups() {
  const { organization_id, workspace_id, cluster_id } = useParams();
  const backupSchedulesQuery = useBackupSchedules({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!,
    backup_type: 'default'
  });

  // let scheduleTime = '';
  // if (dataItem.schedule) {
  //   const scheduleParts = dataItem.schedule.split(' ');
  //   const hours = scheduleParts[1].length === 1 ? `0${scheduleParts[1]}` : scheduleParts[1];
  //   const minutes = scheduleParts[0].length === 1 ? `0${scheduleParts[0]}` : scheduleParts[0];
  //   scheduleTime = `${hours}:${minutes}`;
  // }

  if (backupSchedulesQuery.isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center bg-background">
        <Spinner />
      </div>
    );
  }

  if (backupSchedulesQuery.isError) {
    return <div className="flex flex-1 items-center justify-center bg-base-200">Error</div>;
  }

  if (!backupSchedulesQuery.data) {
    return (
      <div className="flex flex-1 flex-grow items-center justify-center bg-base-200">
        <div>No backups created</div>
      </div>
    );
  }

  return <DefaultBackupsTable data={backupSchedulesQuery.data} />;
}
