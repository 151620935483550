import React from 'react';

import { AddressDTO } from '@/pages/Settings/types';
interface DisplayAddressProps {
  data: AddressDTO;
}
export function DisplayAddress({ data }: DisplayAddressProps) {
  if (!data) {
    return <div className="text-sm">Not available</div>;
  }

  const { street, city, email, state, country, zip_code } = data;
  return (
    <div className="text-sm">
      <div>Email: {email}</div>
      <div>Address: {`${street}, ${city}, ${state}, ${country}, ${zip_code}`}</div>
    </div>
  );
}
