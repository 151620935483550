import React from 'react';

import { ISpinnerStore, useStore as useSpinnerStore } from '@/shared/store/spinner';

const GlobalSpinner = () => {
  const [store] = useSpinnerStore<ISpinnerStore>();
  const { show } = store;
  if (show) {
    return (
      <div className="flex h-[100vh] w-full top-0 left-0 justify-center items-center text-primary-900 fixed bg-black/25 z-20">
        <span className="h-6 w-6 block rounded-full border-4 border-t-gray-800 animate-spin mr-2"></span>
        loading...
      </div>
    );
  } else {
    return <></>;
  }
};

export default GlobalSpinner;
