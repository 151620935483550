import { useCluster } from '@/pages/ClusterList/api/ClusterApi';
import { useUserDetails } from '@/shared/api';
import { GetPermissionNode } from '@/shared/utils';

type UseClusterPermissionsOptions = {
  orgnizationId: string;
  workspaceId: string;
  clusterId: string;
};

export default function useClusterPermissions({
  orgnizationId,
  workspaceId,
  clusterId
}: UseClusterPermissionsOptions) {
  const user = useUserDetails({ organizationId: orgnizationId! });
  const cluster = useCluster({
    organizationId: orgnizationId!,
    workspaceId: workspaceId!,
    clusterId: clusterId!
  });

  let canQuery = false;
  let canMonitor = false;
  let canAlert = false;
  let canMetrics = false;
  let canBackup = false;
  let canDelete = false;
  let canManageUsers = false;
  let canOltpArchive = false;

  if (user.data) {
    const permRoot = new Map(Object.entries(user.data.permissions));
    const clusterPerms = GetPermissionNode(permRoot, [
      'workspaces',
      `id-${String(workspaceId)}`,
      'clusters',
      `id-${String(clusterId)}`
    ]);
    canQuery = clusterPerms?.has('query');
    canMonitor = clusterPerms?.has('monitor');
    canAlert = clusterPerms?.has('alert');
    canMetrics = clusterPerms?.has('metrics');
    canBackup = clusterPerms?.has('backups');
    canDelete = clusterPerms?.has('delete');
    canManageUsers = clusterPerms?.has('users');
    canOltpArchive = clusterPerms?.has('oltp');
  }

  if (cluster?.data?.status !== 'success') {
    canQuery = false;
    canMetrics = false;
    canBackup = false;
    canManageUsers = false;
    canOltpArchive = false;
  }

  return {
    isLoading: user.isLoading || cluster.isLoading,
    isError: user.isError || cluster.isError,
    canQuery,
    canMonitor,
    canAlert,
    canMetrics,
    canBackup,
    canDelete,
    canManageUsers,
    canOltpArchive
  };
}
