import { useMutation } from 'react-query';

import { axios } from '@/shared/utils';

async function restoreBackup(
  organization_id: string,
  workspace_id: string,
  cluster_id: string,
  backup_id: number,
  rename_original_database: boolean
) {
  const response = await axios.post(
    `/organizations/${organization_id}/workspaces/${workspace_id}/clusters/${cluster_id}/backups/${backup_id}/restore?rename_original_database=${rename_original_database}`
  );
  return response.data;
}

interface IRestoreBackupMutationVariables {
  backup_id: number;
  rename_original_database: boolean;
}

type UseRestoreBackupOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export function useRestoreBackup({
  config,
  organizationId,
  workspaceId,
  clusterId
}: UseRestoreBackupOptions) {
  return useMutation({
    ...config,
    mutationFn: ({ backup_id, rename_original_database }: IRestoreBackupMutationVariables) =>
      restoreBackup(organizationId, workspaceId, clusterId, backup_id, rename_original_database)
  });
}
