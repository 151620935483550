import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { IoAdd } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import GenericDashboardLayout from '@/layouts/GenericDashboardLayout';

import { useOrganizationSubscriptionValidity } from '../Organization/api/OrganizationApi';

import { useClusters } from './api/ClusterApi';
import ClusterBar from './components/ClusterBar';
import ClusterPlanLimitAlert from './components/ClusterPlanLimitAlert';
import useClusterPermissions from './hooks/useClusterPermissions';
import { ICluster } from './types';

export default function ClusterList() {
  const { workspace_id, organization_id } = useParams();
  const navigate = useNavigate();

  const useClustersQuery = useClusters({
    organizationId: organization_id!,
    workspaceId: workspace_id!
  });

  const clusterPermissions = useClusterPermissions({
    organizationId: organization_id!,
    workspaceId: workspace_id!
  });

  const {
    isError: isSubscriptionValidityError,
    isLoading: isSubscriptionValidityLoading,
    data: subscriptionValidityData
  } = useOrganizationSubscriptionValidity(organization_id!);

  if (useClustersQuery.isLoading || clusterPermissions.isLoading || isSubscriptionValidityLoading) {
    return (
      <GenericDashboardLayout>
        <div className="flex justify-center items-center h-3/4">
          <Spinner size={'lg'} />
        </div>
      </GenericDashboardLayout>
    );
  }

  if (
    useClustersQuery.isError ||
    clusterPermissions.isError ||
    isSubscriptionValidityError ||
    !subscriptionValidityData
  ) {
    return (
      <GenericDashboardLayout>
        <div className="flex flex-col w-full">
          <div>Error</div>
        </div>
      </GenericDashboardLayout>
    );
  }

  const hasQuota = clusterPermissions.quotaRemaining > 0;

  const handleClusterCreate = () => {
    navigate(`/${organization_id}/workspace/${workspace_id}/create-cluster`);
  };
  const planExpired = subscriptionValidityData?.show_banner;

  if (!useClustersQuery.data || useClustersQuery.data.length === 0) {
    return (
      <GenericDashboardLayout>
        <div className="w-full h-full flex justify-center pb-[20%]">
          <div className="flex justify-center flex-col items-center">
            <div className="font-bold text-xl">No Clusters</div>
            <div className="text-foreground text-sm mt-3">To get started, create a cluster</div>
            <div className="mt-7">
              {clusterPermissions.canCreateCluster && (
                <CreateClusterButton
                  handleCreate={handleClusterCreate}
                  hasQuota={hasQuota}
                  isSubscriptionExpired={planExpired}
                />
              )}
            </div>
          </div>
        </div>
      </GenericDashboardLayout>
    );
  }

  return (
    <GenericDashboardLayout>
      <div className="w-full h-full flex justify-center">
        <div className="flex-1 max-w-5xl my-[5%]">
          <div className="flex justify-between">
            <div className="text-xl font-bold">Clusters</div>
            {clusterPermissions.canCreateCluster && (
              <CreateClusterButton
                handleCreate={handleClusterCreate}
                hasQuota={hasQuota}
                isSubscriptionExpired={planExpired}
              />
            )}
          </div>
          <div className="flex pt-7 w-full flex-col gap-2">
            {useClustersQuery.data?.map((cluster: ICluster) => (
              <div key={cluster.id} className="max-w-[1500px] flex-1">
                <ClusterBar cluster={cluster} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </GenericDashboardLayout>
  );
}

type CreateClusterButtonProps = {
  hasQuota: boolean;
  isSubscriptionExpired: boolean;
  handleCreate: () => void;
};

function CreateClusterButton({
  hasQuota,
  isSubscriptionExpired,
  handleCreate
}: CreateClusterButtonProps) {
  if (isSubscriptionExpired) {
    return (
      <div className="flex justify-center items-center gap-2">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="h-4 w-4">
                <FiAlertTriangle />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Plan has expired</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <Button disabled>
          <IoAdd className="h-8 w-8 pr-2" /> Create Cluster
        </Button>
      </div>
    );
  }

  if (!hasQuota) {
    return <ClusterPlanLimitAlert />;
  }

  return (
    <Button onClick={handleCreate}>
      <IoAdd className="h-8 w-8 pr-2" /> Create Cluster
    </Button>
  );
}
