import { createColumnHelper } from '@tanstack/react-table';

import DataTable from '@/components/ui/datatable';

import { IClusterUser } from '../types';

import DeleteClusterUser from './DeleteClusterUser';
import EditClusterUser from './EditClusterUser';

const columnHelper = createColumnHelper<IClusterUser>();

const columns = [
  columnHelper.accessor('username', {
    header: () => 'User Name'
  }),
  columnHelper.accessor('host_ip', {
    header: () => 'Host IP'
  }),
  columnHelper.accessor('role', {
    header: () => 'Role'
  }),
  columnHelper.display({
    header: () => 'actions',
    id: 'actions',
    cell: ({ row }) => {
      return (
        <div className="flex gap-2">
          <EditClusterUser username={row.original.username} />
          <DeleteClusterUser username={row.original.username} />
        </div>
      );
    }
  })
];

type ClusterUserTableProps = {
  data: any;
};

export default function ClusterUserTable({ data }: ClusterUserTableProps) {
  return <DataTable columns={columns} data={data} />;
}
