import React from 'react';
import { IoAdd } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import PageTitle from '@/shared/components/Typography/PageTitle';

import { useUserList } from './api/UserManagementApi';
import UserTable from './components/UserTable';

export default function UserManagement() {
  const { organization_id } = useParams();
  const navigate = useNavigate();
  const { isLoading, data, error, isError }: any = useUserList({
    organizationId: organization_id!
  });

  function handleClick() {
    navigate('invite');
  }

  if (isLoading) {
    return <Spinner size={'lg'} />;
  }

  if (isError) {
    return (
      <>
        <div>{error.message}</div>{' '}
      </>
    );
  }

  return (
    <div className="flex-1">
      <PageTitle
        title="Users"
        rightComponent={
          <Button className="float-right" onClick={handleClick}>
            <IoAdd className="h-8 w-8 pr-2" /> Invite User
          </Button>
        }
      />
      <div className="pt-7">
        <UserTable data={data} />
      </div>
    </div>
  );
}
