import React, { useEffect, useState } from 'react';
import { RiFileCopyLine } from 'react-icons/ri';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { checkIfClipboardAPI, copyToClipboard } from '@/shared/utils/clipboard';

type Props = {
  content: string;
  iconClass?: string;
  buttonClass?: string;
  containerClass?: string;
};

export default function CopyToClipboardButton({ content }: Props) {
  const canCopy = checkIfClipboardAPI();
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  useEffect(() => {
    if (copiedToClipboard) {
      setTimeout(() => {
        setCopiedToClipboard(false);
      }, 1000);
    }
  }, [copiedToClipboard]);

  async function handleCopyToClipboard() {
    await copyToClipboard(content);
    setCopiedToClipboard(true);
  }

  if (canCopy) {
    return (
      <TooltipProvider>
        <Tooltip open={copiedToClipboard}>
          <TooltipTrigger onClick={handleCopyToClipboard}>
            <RiFileCopyLine />
          </TooltipTrigger>
          <TooltipContent>
            <p>Copied!</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return null;
}
