import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { useParams } from 'react-router-dom';

import { useToast } from '@/components/ui/use-toast';
import { SpinnerIcon } from '@/shared/components/elements/Spinner/Spinner';

import { useExportQuery } from '../api/QueryEditorApi';
import { QueryStats } from '../types';

type QueryStatsPanelProps = {
  queryStats: QueryStats | null;
  activeQuery: string;
  credentialId: string;
};

function triggerFileDownload(link: string) {
  const downloadLink = document.createElement('a');
  downloadLink.href = link;
  downloadLink.click();
}

function formatBytes(bytes: number) {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(4))} ${sizes[i]}`;
}

export default function QueryStatsPanel({
  queryStats,
  activeQuery,
  credentialId
}: QueryStatsPanelProps) {
  const { cluster_id, workspace_id, organization_id } = useParams();
  const { toast } = useToast();

  const exportQueryMutation = useExportQuery({
    organizationId: organization_id!,
    workspaceId: workspace_id!,
    clusterId: cluster_id!,
    credentialId: credentialId,
    config: {
      onSuccess: (res: any) => {
        triggerFileDownload(res.link);
        toast({
          title: 'Download Started'
        });
      },
      onError: () => {
        toast({
          title: 'Export Failed',
          variant: 'destructive'
        });
      }
    }
  });

  function handleFileExport() {
    exportQueryMutation.mutate({ query: activeQuery, format: 'csv' });
  }

  if (queryStats === null) {
    return null;
  }
  if (Array.isArray(queryStats)) {
    return null;
  }

  if (!queryStats.success) {
    const status = (
      <span className="flex text-error items-center">
        <AiOutlineCloseCircle size={'1.2em'} className="mr-1" />
        Failed
      </span>
    );
    return (
      <div className="flex w-full p-2 justify-between font-sans">
        <div className="flex text-xs">
          <span className="font-semibold">Query Results:</span>
          <span className="ml-2">{status}</span>
        </div>
      </div>
    );
  }

  const status = (
    <span className="flex text-success-200 items-center">
      <AiOutlineCheckCircle size={'1.2em'} className="mr-1" /> Success
    </span>
  );
  const numberOfRows = queryStats.rows.length;
  const timeTaken = queryStats.took;
  const sizeRead = formatBytes(queryStats.bytes);

  return (
    <div className="flex w-full py-3 px-4 font-sans justify-between">
      <div className="flex">
        <div className="flex text-xs">
          <span className="font-semibold">Query Results: &nbsp;</span>
          <span>{status}</span>
          <span className="ml-5">{numberOfRows} rows</span>
        </div>
        <div className="text-xs text-chista-grey ml-9">
          <span>Took: {timeTaken}</span>
          <span>, </span>
          <span>Read: {sizeRead}</span>
        </div>
      </div>
      <div className="text-xs cursor-pointer text-blue-600" onClick={handleFileExport}>
        {exportQueryMutation.isLoading ? <SpinnerIcon size={4} /> : 'Export to CSV'}
      </div>
    </div>
  );
}
