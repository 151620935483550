import React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { useClusterStatusMutation } from '@/pages/ClusterList/api/ClusterApi';
import { ICluster } from '@/pages/ClusterList/types';

type ClusterSleepCardProps = {
  cluster: ICluster;
};

export default function ClusterSleepCard({ cluster }: ClusterSleepCardProps) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const { organization_id, workspace_id, cluster_id } = useParams();

  const clusterStatusMutation = useClusterStatusMutation({
    organizationId: organization_id!,
    clusterId: cluster_id!,
    workspaceId: workspace_id!,
    config: {
      onSuccess: () => {
        queryClient.invalidateQueries('clusters');
        toast({
          title: 'Cluster status updated.'
        });
        queryClient.invalidateQueries('cluster');
      },
      onError: () => {
        toast({
          title: 'Failed to update cluster status',
          variant: 'destructive'
        });
      }
    }
  });

  function handleClick(status: string) {
    clusterStatusMutation.mutate({ status });
  }

  if (cluster.status === 'success') {
    return (
      <div className="border rounded px-4 py-6">
        <div className="">Sleep Cluster</div>
        <div className="flex justify-between items-center">
          <div className="text-sm">The cluster will be placed in a dormant state.</div>
          <Button onClick={() => handleClick('stop')} className="border-0 px-4 mr-2 h-[40px]">
            Sleep Cluster
          </Button>
        </div>
      </div>
    );
  } else if (cluster.status === 'sleeping') {
    return (
      <div className="border rounded px-4 py-6">
        <div className="">Start Cluster</div>
        <div className="flex justify-between items-center">
          <div className="text-sm">This will start the cluster</div>
          <Button onClick={() => handleClick('start')} className="border-0 px-4 mr-2 h-[40px]">
            Start Cluster
          </Button>
        </div>
      </div>
    );
  } else if (cluster.status === 'starting') {
    return (
      <div className="border rounded px-4 py-6">
        <div className="">Sleep Cluster</div>
        <div className="flex justify-between items-center">
          <div className="text-sm">The cluster is starting</div>
          <Button variant={'ghost'} disabled className="border-0 px-4 mr-2 h-[40px] ">
            Sleep
          </Button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
