import { useUnwrap } from 'chista-state-manager';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { IContextMenu, contextMenu$, closeContextMenu } from '@/shared/store/context-menu';
import { useOutsideClickListener } from '@/shared/utils/hooks/useOutsideClickListener';

const GlobalContextMenu = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | undefined>(0);
  const menuContext: IContextMenu = useUnwrap(contextMenu$)!;

  useOutsideClickListener(wrapperRef, closeContextMenu);

  const handleResize = () => {
    setHeight(wrapperRef?.current?.offsetHeight);
  };

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setHeight(wrapperRef?.current?.offsetHeight);
  }, [menuContext]);

  let wrapperStyles;
  if (Number((menuContext?.position?.y ?? 0) + (height ?? 0)) > Number(window.innerHeight)) {
    wrapperStyles = {
      top: Number((menuContext?.position?.y ?? 0) - (height ?? 0)),
      left: menuContext?.position?.x
    };
  } else {
    wrapperStyles = { top: menuContext?.position?.y, left: menuContext?.position?.x };
  }

  return (
    <>
      {menuContext?.open && (
        <div
          className="menu-context min-w-[150px] fixed bg-base-475 rounded shadow-md border border-base-470"
          style={wrapperStyles}
          ref={wrapperRef}
        >
          <ul>
            <li></li>
            {menuContext?.menuItems?.map((menuItem, index) => {
              return (
                <li
                  key={index}
                  onClick={(e) => {
                    menuItem.onClick(e);
                    closeContextMenu();
                  }}
                  className="menu-item-context py-2 px-4 hover:bg-base-470 hover:text-white text-sm"
                >
                  {menuItem.title}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};
export default GlobalContextMenu;
