import MetricsApp from 'metricsRemote/MetricsApp';
// import OLTPArchiveApp from 'OLTPArchiveRemote/OLTPArchiveApp';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Routes, Route, Outlet } from 'react-router-dom';

import Backups from '@/pages/Backups/Backups';
import BackupSchedules from '@/pages/Backups/BackupSchedules';
import Cluster from '@/pages/ClusterDashboard/Cluster/Cluster';
import ClusterDashboard from '@/pages/ClusterDashboard/ClusterDashboard';
import ClusterList from '@/pages/ClusterList/ClusterList';
import ClusterOverview from '@/pages/ClusterOverview/ClusterOverview';
import ClusterSettings from '@/pages/ClusterSettings/ClusterSettings';
import ClusterUsers from '@/pages/ClusterUsers/ClusterUsers';
import { LOGIN_PATH, Login } from '@/pages/Login';
import NotFound from '@/pages/NotFound';
import Organization from '@/pages/Organization/Organization';
import QueryEditor from '@/pages/QueryEditor/QueryEditor';
import Billing from '@/pages/Settings/components/Billing/Billing';
import Profile from '@/pages/Settings/components/GeneralProfile/GeneralProfile';
import Plans from '@/pages/Settings/components/Plans/Plans';
import UserManagement from '@/pages/Settings/components/UserManagement/UserManagement';
import Settings from '@/pages/Settings/Settings';
import Unauthorized from '@/pages/Unauthorized';
import Workspace from '@/pages/Workspace/Workspace';
import Spinner from '@/shared/components/elements/Spinner/Spinner';

import { Toaster } from './components/ui/toaster';
import CreateCluster from './pages/ClusterList/CreateCluster';
import CreateClusterUser from './pages/ClusterUsers/CreateClusterUser';
import UpdateClusterUser from './pages/ClusterUsers/UpdateClusterUser';
import Logs from './pages/Logs/Logs';
import CreateBackup from './pages/Metadata/CreateBackup';
import InviteUser from './pages/Settings/components/UserManagement/InviteUser';
import UpdateAddress from './pages/Settings/UpdateAdress';
import CreateWorkspace from './pages/Workspace/CreateWorkspace';

export default function App() {
  const fallbackElement = (
    <div className="flex items-center justify-center w-screen h-screen">
      <Spinner />
    </div>
  );

  return (
    <>
      <Suspense fallback={fallbackElement}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Routes>
            <Route path={LOGIN_PATH} element={<Login />} />

            <Route path="/" element={<Organization />} />

            <Route path=":organization_id" element={<Org />}>
              <Route path="settings" element={<Settings />}>
                <Route path="general-profile" element={<Profile />} />
                <Route path="update-address" element={<UpdateAddress />} />
                <Route path="billing" element={<Billing />} />
                <Route path="plans" element={<Plans />} />
                <Route path="users" element={<UserManagement />} />
                <Route path="users/invite" element={<InviteUser />} />
              </Route>
              <Route path="" element={<Workspace />} />
              <Route path="create-workspace" element={<CreateWorkspace />} />
              <Route path="workspace/:workspace_id" element={<ClusterDashboard />}>
                <Route path="" element={<ClusterList />} />
                <Route path="create-cluster" element={<CreateCluster />} />
                <Route path="cluster/:cluster_id" element={<Cluster />}>
                  <Route path="metrics" element={<MetricsApp />} />
                  <Route path="overview" element={<ClusterOverview />} />
                  <Route path="query-editor" element={<QueryEditor />} />
                  <Route path="backups" element={<Backups />} />
                  <Route path="backups/create" element={<CreateBackup />} />
                  <Route path="backup-schedules" element={<BackupSchedules />} />
                  <Route path="logs" element={<Logs />} />
                  <Route path="settings" element={<ClusterSettings />} />
                  <Route path="user-management" element={<ClusterUsers />} />;
                  <Route path="user-management/create" element={<CreateClusterUser />} />
                  <Route path="user-management/edit/:username" element={<UpdateClusterUser />} />
                  {/* <Route path="oltp-archive/*" element={<OLTPArchiveApp />} /> */}
                </Route>
              </Route>
            </Route>

            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ErrorBoundary>
      </Suspense>
      <Toaster />
    </>
  );
}

const Org = () => <Outlet />;

const ErrorFallback = () => {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen" role="alert">
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </button>
    </div>
  );
};
