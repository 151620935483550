import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';

interface SubscriptionCardProps {
  tier: {
    amount: number;
    name: string;
    label: string;
    isUpgradable?: boolean;
    startDate?: string;
    numberOfDaysRemaining?: number;
    isActive?: boolean;
    level: number;
    available: boolean;
  };
  upgradeAction?: (tier: string) => void;
  subscriptionValidityData: any;
}

export function SubscriptionCard({
  tier,
  upgradeAction,
  subscriptionValidityData
}: SubscriptionCardProps) {
  const { organization_id } = useParams();
  console.log(tier);
  const { label, amount, isActive, numberOfDaysRemaining, isUpgradable, startDate, name } = tier;
  console.log(tier.isUpgradable);
  const amountText =
    name !== 'critical_business' ? (
      <span>
        ${amount} / <span className="text-chista-grey">Month</span>
      </span>
    ) : (
      <span className="font-normal text-sm text-chista-grey">Need Assessment</span>
    );

  return (
    <div
      className={`border border-solid  px-4 py-7 min-w-[380px] max-w-[380px] flex-1 ${
        isActive ? 'border-primary-100' : 'border-base-475'
      }`}
    >
      <div className="flex text-xl font-bold">
        <div className="flex-1">{label}</div>
        <div className="flex-1 text-right">{amountText}</div>
      </div>

      <div className="flex min-h-[24px]">
        {isActive && numberOfDaysRemaining ? `${numberOfDaysRemaining} days remaining` : null}
      </div>

      <div className="flex mt-8 items-center min-h-[43px]">
        {isActive ? (
          // <Button.Link>Cancel Subscription</Button.Link>
          <div>
            {subscriptionValidityData?.show_banner ? (
              tier.name === 'free' ? null : (
                <Button onClick={() => upgradeAction?.(tier.name)}>Renew Plan</Button>
              )
            ) : startDate ? (
              <div className="text-sm">
                <span className="font-bold">Start Date</span>: {startDate}
              </div>
            ) : null}
          </div>
        ) : isUpgradable ? (
          <Button onClick={() => upgradeAction?.(tier.name)}>Upgrade Plan</Button>
        ) : null}
        {!isActive ? (
          <Link
            className={`${isUpgradable !== false ? 'ml-5' : ''}`}
            to={`/${organization_id}/settings/plans`}
          >
            <div className="flex text-primary items-center">
              Learn More&nbsp;&nbsp; <BsArrowRight />
            </div>
          </Link>
        ) : null}
      </div>
    </div>
  );
}
