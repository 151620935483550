import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Spinner from '@/shared/components/elements/Spinner/Spinner';
import { API_URL } from '@/shared/config';

import { UseOrganizations } from './api/OrganizationApi';
import OrgSelectionModal from './components/OrgSelectionModal';
import OrganizationLayout from './layout/OrganizationLayout';

export default function Organization() {
  const [showChild, setShowChild] = useState(false);
  const organizationsQuery = UseOrganizations();
  const navigate = useNavigate();

  useEffect(() => {
    if (organizationsQuery?.data?.length === 1) {
      const orgId = organizationsQuery?.data[0].organization_id;
      navigate(`${orgId}`);
    } else {
      setShowChild(true);
    }
  }, [organizationsQuery.data, navigate]);

  if (organizationsQuery.isLoading) {
    return (
      <OrganizationLayout>
        <Spinner />
      </OrganizationLayout>
    );
  }

  if (organizationsQuery.isError) {
    if (
      (organizationsQuery as any)?.error.response?.status === 401 ||
      (organizationsQuery as any)?.error.response?.status === 400
    ) {
      console.log('Unauthenticated');
      window.location.href = `${API_URL}/login`;
    }
    return (
      <OrganizationLayout>
        <div>{(organizationsQuery as any).error.message}</div>
      </OrganizationLayout>
    );
  }
  return (
    <>
      {showChild && (
        <OrganizationLayout>
          <div className="flex justify-center items-center h-5/6">
            <OrgSelectionModal organizationList={organizationsQuery.data} />
          </div>
        </OrganizationLayout>
      )}
    </>
  );
}
