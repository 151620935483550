import { useQuery } from 'react-query';

import { axios } from '@/shared/utils';

async function getCredentials(orgId: string, workspaceId: string, clusterId: string) {
  const res = await axios.get(
    `/organizations/${orgId}/workspaces/${workspaceId}/clusters/${clusterId}/credentials`
  );
  return res.data;
}

type UseCredentialOptions = {
  config?: object;
  organizationId: string;
  workspaceId: string;
  clusterId: string;
};

export default function useCredentials({
  config,
  organizationId,
  workspaceId,
  clusterId
}: UseCredentialOptions) {
  return useQuery({
    ...config,
    queryKey: ['credentials', clusterId],
    queryFn: () => getCredentials(organizationId, workspaceId, clusterId)
  });
}
