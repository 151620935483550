import { IoIosHelpCircleOutline } from 'react-icons/io';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem
} from '@/components/ui/dropdown-menu';

export default function Help() {
  const docsLink = (
    <a
      className="w-full"
      href="https://docs.cloud.chistadata.io/getting-started/index.html"
      target="_blank"
      rel="noreferrer"
    >
      Docs
    </a>
  );

  const helpLink = (
    <a
      className="w-full"
      href="https://support.chistadata.com/portal/en/signin"
      target={'_blank'}
      rel={'noreferrer'}
    >
      Help
    </a>
  );

  const statusLink = (
    <a className="w-full" href={window.status_url} target={'_blank'} rel={'noreferrer'}>
      Status
    </a>
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <IoIosHelpCircleOutline className="w-6 h-6" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem>{statusLink}</DropdownMenuItem>
        <DropdownMenuItem>{docsLink}</DropdownMenuItem>
        <DropdownMenuItem>{helpLink}</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
